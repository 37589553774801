import React, {FC} from "react";
import {Box, CssBaseline} from "@mui/material";
import Navbar from "@/components/NavBar/NavBar";
import LayoutProps from "@/utils/types/components/layout";

const Layout: FC<LayoutProps> = ({children}) => {
  return (
    <>
      <CssBaseline />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minWidth: '100vw',
        minHeight: '100vh',
        flexGrow: 1,
      }}>
        <Navbar />
        {children}
      </Box>

    </>

  );
};

export default Layout;