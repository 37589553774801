import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from "@mui/material/styles"

interface PropsMainButton {
  typeButton: "submit" | "button";
  variant?: "text" | "outlined" | "contained";
  disabled: boolean;
  testId: string;
  children: React.ReactNode;
  onClick?: () => void;
  style?: SxProps<Theme>;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium" | "large";
  ariaLabel?: string;
  ariaControls?: string;
  ariaExpanded?: boolean;
  ariaHaspopup?: boolean | "dialog" | "menu" | "grid" | "true" | "false" | "listbox" | "tree";
}

const Button: React.FC<PropsMainButton> = (
  {
    typeButton,
    variant,
    disabled,
    testId,
    children,
    onClick,
    color,
    size,
    style,
    ariaControls,
    ariaExpanded,
    ariaHaspopup
  }
) => {
  return (
    <MuiButton
      sx={style}
      type={typeButton}
      disabled={disabled}
      size={size}
      color={color}
      data-testid={testId}
      onClick={onClick}
      variant={variant || "contained"}
      aria-controls={ariaControls || undefined}
      aria-expanded={ariaExpanded ? 'true' : undefined}
      aria-haspopup={ariaHaspopup || undefined}
    >
      {children}
    </MuiButton>
  )
}

export default Button;