import {Task} from "@/utils/types/store/tasks/backendAPI";

function sortedByTime(tasksData: Task[], order: string = 'desc'): Task[] {

  return tasksData.sort((a: Task, b: Task) => {
    if (order === 'asc') {
      if (a.time_added > b.time_added) {
        return 1;
      }
      if (a.time_added < b.time_added) {
        return -1;
      }
      return 0;
    } else {
      if (a.time_added < b.time_added) {
        return 1;
      }
      if (a.time_added > b.time_added) {
        return -1;
      }
      return 0;
    }
  });
}

export default sortedByTime;