import {Box} from "@mui/material";
import * as React from "react";

interface StatusBadgeProps {
  status: string
}

const StatusBadge: React.FC<StatusBadgeProps> = ({status}: StatusBadgeProps) => {
  switch (status) {
    case 'done':
      return (
        <Box component="span" sx={{ bgcolor: 'success.main',  width: 15, height: 15, borderRadius: "50%", mx: 4 }}/>
      )
    case 'failed':
      return (
        <Box component="span" sx={{ bgcolor: 'error.main',  width: 15, height: 15, borderRadius: "50%", mx: 4 }}/>
      )
    case 'running':
      return (
        <Box component="span" sx={{ bgcolor: 'info.light',  width: 15, height: 15, borderRadius: "50%", mx: 4 }}/>
      )
    case 'pending':
      return (
        <Box component="span" sx={{ bgcolor: 'text.secondary',  width: 15, height: 15, borderRadius: "50%", mx: 4 }}/>
      )
    case 'canceled':
      return (
        <Box component="span" sx={{ bgcolor: 'warning.main',  width: 15, height: 15, borderRadius: "50%", mx: 4 }}/>
      )
    default:
      return (
        <Box component="span" sx={{ bgcolor: 'grey.500',  width: 15, height: 15, borderRadius: "50%", mx: 4 }}/>
      )
  }
}

export default StatusBadge;