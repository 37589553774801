import React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails, Typography,
} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface AccordionProps {
  id: string,
  title: string,
  children: React.ReactNode
}

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
  return (
    <MuiAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`control-${props.id}`}
        id={props.id}
      >
        <Typography variant="body1"> {props.title} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {props.children}
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion;