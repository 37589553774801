import {createSlice} from '@reduxjs/toolkit';
import { RootState } from "@/store";
import serverUrlList from "@/service/BackEnd/server";
import BackendServerUrl from "@/utils/types/store/authBE/backendServerUrl";

const initialState: BackendServerUrl = {
  name: 'preprod',
  url: serverUrlList.preprod
};

const backendServerUrl = createSlice({
  name: 'serverUrl',
  initialState,
  reducers: {
    setServerUrl: (state, action) => {
      state.url = action.payload;
    },
    setServerName: (state, action) => {
      state.name = action.payload;
    }
  }
});


export const {setServerUrl, setServerName} = backendServerUrl.actions;
export default backendServerUrl.reducer;
export const selectServerUrl = (state: RootState) => state.serverUrl.url;
export const selectServerName = (state: RootState) => state.serverUrl.name;