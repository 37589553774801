import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import AdbIcon from '@mui/icons-material/Adb';
import WarningIcon from '@mui/icons-material/Warning';
import * as React from "react";

export const TaskErrorIcon = () => {
  return (
    <ErrorIcon sx={{ mx: 2, color: '#e15757'}}/>
  )
}

export const TaskInfoIcon = () => {
  return (
    <InfoIcon sx={{ mx: 2, color: '#77c9ec'}}/>
  )
}

export const TaskWarningIcon = () => {
  return (
    <WarningIcon sx={{ mx: 2, color: '#e8da2d'}}/>
  )
}

export const TaskDebugIcon = () => {
  return (
    <AdbIcon sx={{  mx: 2, color: '#87b1f1'}}/>
  )
}