import React from "react";
import {
  Box,
} from "@mui/material";
import FiltersControlForm from "@/pages/TaskManager/PipelineDetails/FiltersControlForm";
import PipelineDetails from "@/pages/TaskManager/PipelineDetails/PipelineDetails";



const PipelineLayout = () => {
  return (
    <Box gap={3}>
      <FiltersControlForm/>
      <PipelineDetails />
    </Box>
  )
}

export default PipelineLayout;