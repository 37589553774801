import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridInitialState,
} from '@mui/x-data-grid-pro'

const initialStateDataGrid: GridInitialState = {
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field]
  }
}


export default initialStateDataGrid;