import * as React from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Divider
} from "@mui/material";
import {SignOutButton} from "@/components/NavBar/AuthMSALButton/SignOutButton";
import {useMsal} from "@azure/msal-react";
import Account from "@/utils/types/components/account";

const NavbarAccMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {accounts} = useMsal();
  const [account_detail, setAccountDetail] = React.useState<Account>({
    name: "",
    email: "",
    initials: ""
  });

  React.useEffect(() => {
    if (accounts[0].name !== undefined){
      setAccountDetail({
        name: accounts[0].name,
        email: accounts[0].username,
        initials: accounts[0].name.split(", ").map((n: string) => n[0].toUpperCase()).join("")
      })
    }
  }, [accounts, setAccountDetail])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box>
        <Tooltip title="Account details">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{width: 32, height: 32}}> {account_detail.initials} </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{"&:hover": {backgroundColor: "transparent", }}} disabled> {account_detail.name} </MenuItem>
        <Divider />
        <SignOutButton />
      </Menu>
    </React.Fragment>

  )
}

export default NavbarAccMenu;