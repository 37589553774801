import React from 'react';
import { SelectChangeEvent } from '@mui/material/Select'
import { Select, MenuItem } from '@mui/material';
import { FormControl } from '@/components/Forms';
import Filters from "@/utils/types/service/filters";

interface SelectFormMenuProps {
  options: {
    size: 'small' | 'medium' | undefined;
    fullWidth: boolean;
  };
  disabled?: boolean;
  label: string;
  defaultValue: string | undefined;
  onChange?: (value: string) => void;
  values: Array<Filters>;
}

export const SelectFormControl: React.FC<SelectFormMenuProps> = (props: SelectFormMenuProps) => {
  
  const [
    value,
    setValue
  ] = React.useState<string | undefined>(props.defaultValue);
  
  const handleChange = React.useCallback((event: SelectChangeEvent) => {
    setValue(event.target.value)
    
    props.onChange && props.onChange(event.target.value as string);
  },[props])
  
  // Update the value if the defaultValue changes
  React.useEffect(() => {
    if (props.defaultValue !== value) setValue(props.defaultValue)
  }, [props.defaultValue, value]);
  
  return (
    <FormControl
      label={props.label}
      size={props.options.size}
      fullWidth={props.options.fullWidth}
    >
      <Select
        labelId={`filter-${props.label}`}
        label={props.label}
        data-testid={`selectFilterTest-${props.label}`}
        value={value}
        id={`selectFilter-${props.label}`}
        onChange={handleChange}
        disabled={ props.disabled ? props.disabled : false}
        >
        {
          props.values.map(({
            id,
            value,
            displayName,
          }) => {
            return (
              <MenuItem key={id} value={value}>{displayName}</MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )
}

export default SelectFormControl;