import {combineReducers} from "redux";
import {tasksAPI} from "@/store/tasks/tasksAPI";
import {converterApi} from "@/store/converter/converterApi";
import {generalApi} from "@/store/general/generalApi";
import authSlice from "@/store/authBE/authTokenInf";
import guidSlice from "@/store/tasks/infoTask";
import requestTaskSlice from "@/store/tasks/requestTasks";
import alertsSlice from "@/store/alerts/alertsSlice";
import serverUrl from "@/store/authBE/backendServerUrl";
import taskLogFilter from "@/store/tasks/taskLogFilter";
import listCharts from "@/store/converter/listCharts";
import gridState from "@/store/converter/gridState";
import actionsManager from "@/store/converter/actionsManager";
import redoUndoController from "@/store/converter/redoUndoController";

const rootReducer = combineReducers({
  [ tasksAPI.reducerPath ]: tasksAPI.reducer,
  [ converterApi.reducerPath ]: converterApi.reducer,
  [ generalApi.reducerPath ]: generalApi.reducer,
  auth: authSlice,
  selectedGuid: guidSlice,
  requestTasksParameters: requestTaskSlice,
  alerts: alertsSlice,
  serverUrl: serverUrl,
  taskLogFilter: taskLogFilter,
  listCharts: listCharts,
  gridState: gridState,
  actionsManager: actionsManager,
  redoUndoController: redoUndoController
});


export default rootReducer;