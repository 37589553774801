import React from 'react';
import {
  FormControl as MuiFormControl,
  InputLabel,
} from "@mui/material";

interface FormControlProps {
  label: string;
  size: 'small' | 'medium' | undefined;
  fullWidth: boolean;
  children: React.ReactElement;
}

const FormControl: React.FC<FormControlProps> = (props: FormControlProps) => {
  return (
    <MuiFormControl size={props.size} fullWidth={props.fullWidth}>
      <InputLabel id={`filter-${props.label}`}> {props.label} </InputLabel>
      {props.children}
    </MuiFormControl>
  )
}

export default FormControl;