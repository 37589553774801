import {createSlice} from '@reduxjs/toolkit';
import { RootState } from "@/store";


const initialState: {
  status: string,
  tag: string,
} = {
  status: "",
  tag: "",
}

const requestTaskSlice = createSlice({
  name: 'requestTasksParameters',
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.tag = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    }
  }
});

export const {
  setTag,
  setStatus,
} = requestTaskSlice.actions;
export default requestTaskSlice.reducer;
export const selectTag = (state: RootState) => state.requestTasksParameters.tag;
export const selectStatus = (state: RootState) => state.requestTasksParameters.status;