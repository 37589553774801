import { configureStore } from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useSelector, useDispatch} from "react-redux";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from "@/store/reducers";
import { tasksAPI } from "@/store/tasks/tasksAPI";
import { converterApi } from "@/store/converter/converterApi";
import { generalApi } from "@/store/general/generalApi";


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  timeout: 0,
  whitelist: ['serverUrl', 'gridState', 'actionsManager' ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
      }
    }).concat(tasksAPI.middleware, converterApi.middleware, generalApi.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;