import React from "react";
import { Modal as MuiModal, Box, Typography } from "@mui/material";

const styleModal ={
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  borderRadius: 2,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: 600
}

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  testId?: string;
  children?: React.ReactElement;
  arialabelledby?: string;
  ariadescribedby?: string;
  modalTitle: string;
}


const Modal: React.FC<ModalProps> = (
  {
    open,
    onClose,
    children,
    arialabelledby = "modal-modal-title",
    ariadescribedby = "modal-modal-description",
    modalTitle,
    testId = "test-modal-id",
  } : ModalProps
) => {
  
  return(
    <MuiModal
      open={open}
      onClose={onClose}
      data-testid={testId}
      aria-labelledby={arialabelledby}
      aria-describedby={ariadescribedby}
    >
      <Box
        sx={styleModal}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            id={arialabelledby}
            variant="h6"
            component="h2"
          >
            {modalTitle}
          </Typography>
        </Box>
        
        {children}
      </Box>
    </MuiModal>
  )
}

export default Modal;