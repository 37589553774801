import {createApi} from '@reduxjs/toolkit/query/react';
import {
  Task,
  ServerTaskResponse,
  TaskParametersRequest,
  GuidTaskRequest
} from "@/utils/types/store/tasks/backendAPI";
import sortedByTime from "@/service/Tasks/SortedByTime";
import customFetchBase from "@/service/BackEnd/customFetch/customFetchBase";


export const tasksAPI = createApi({
  reducerPath: 'Tasks/api',
  baseQuery: customFetchBase,
  endpoints: build => ({
    getTasksByTime: build.query<Task[], TaskParametersRequest>({
      query: (taskParams: TaskParametersRequest) => ({
        url: `${taskParams.serverUrl}/builder/api/tasks`,
        method: 'GET',
        params: {
          tag: taskParams.tag,
          age: taskParams.age,
          updated: taskParams.updated
        }
      }),
      transformResponse(response: ServerTaskResponse) {
        return (
          sortedByTime(response.tasks)
        )
      }
    }),
    getTasksByGuid: build.query<string[], GuidTaskRequest>({
      query: (taskGuid: GuidTaskRequest) => ({
        url: `${taskGuid.serverUrl}/builder/api/tasks/${taskGuid.guid}/output`,
        method: 'GET',
        responseHandler: 'text'
      }),
      transformResponse(response: string) {
        return response.split('\r\n');
      }
    })
  }),
});

export const {useGetTasksByGuidQuery, useGetTasksByTimeQuery} = tasksAPI;