import React from "react";
import { Box } from "@mui/material";
import { ButtonWithList } from "@/components/Buttons";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { useTypedSelector } from "@/store";
import {
  selectActionsManagerUndo,
  selectActionsManagerRedo,
} from "@/store/converter/actionsManager";
import { useUpdateCellValueMutation } from "@/store/converter/converterApi";
import {selectServerUrl} from "@/store/authBE/backendServerUrl";
import {addAlert} from "@/store/alerts/alertsSlice";
import {useAppDispatch} from "@/store";
import { setRedo, setUndo, setReduceLastUndo, setReduceLastRedo } from "@/store/converter/actionsManager";
import {setExecuteUndoRedo, selectDisableButtons, setRefreshData} from "@/store/converter/redoUndoController";



const UndoRedoControl = () => {
  
  const dispatch = useAppDispatch();
  
  const serverUrl = useTypedSelector(selectServerUrl);
  const disableButtons = useTypedSelector(selectDisableButtons);
  
  const undoArrayState = useTypedSelector(selectActionsManagerUndo)
  const redoArrayState = useTypedSelector(selectActionsManagerRedo)
  
  const undoArray = React.useMemo(() => {
    return undoArrayState.map(
      (item, index) => {
        return {
          itemIndex: index,
          message: `Changed ${item.prevValue} in column ${item.column}`,
        };
      }).reverse();
  },[undoArrayState]);
  
  const redoArray = React.useMemo(() => {
    return redoArrayState.map(
      (item, index) => {
        return {
          itemIndex: index,
          message: `Changed ${item.nextValue} in column ${item.column}`
        };
      }).reverse();
  }, [redoArrayState]);
  
  const [updateCellValue] = useUpdateCellValueMutation();
  
  const [lock, setLock] = React.useState<boolean>(false);
  
  // handlers
  
  const handleRedo = React.useCallback((index: number) => {
    setLock(true);
    dispatch(setExecuteUndoRedo(true));
    updateCellValue({
      serverUrl: serverUrl,
      requestBody: {
        name: redoArrayState[index].name,
        column: redoArrayState[index].column,
        value: redoArrayState[index].nextValue,
      }
    }).unwrap().then(
      () => {
        for (let i = redoArrayState.length - 1; i >= index; i--) {
          dispatch(setUndo(redoArrayState[i]))
          dispatch(setReduceLastRedo())
        }
      }
    ).catch(
      (error) => {
        dispatch(addAlert({
          severity: "error",
          message: `Something went wrong when updating the value of ${redoArrayState[index].nextValue} in column ${redoArrayState[index].column}`,
          open: true,
        }));
        console.log(error)
      }
    ).finally(() => {
      setLock(false);
      dispatch(setExecuteUndoRedo(false));
      dispatch(setRefreshData(true));
    })
  
  }, [redoArrayState, serverUrl, updateCellValue, dispatch]);
  
  
  const handleUndo = React.useCallback((index: number) => {
    setLock(true);
    
    dispatch(setExecuteUndoRedo(true));
    
    updateCellValue({
      serverUrl: serverUrl,
      requestBody: {
        name: undoArrayState[index].name,
        column: undoArrayState[index].column,
        value: undoArrayState[index].prevValue,
      }
    }).unwrap().then(
      () => {
        for (let i = undoArrayState.length - 1; i >= index; i--) {
          dispatch(setRedo(undoArrayState[i]));
          dispatch(setReduceLastUndo());
        }
      }
    ).catch(
      (error) => {
        dispatch(addAlert({
          severity: "error",
          message: `Something went wrong when updating the value of ${undoArrayState[index].prevValue} in column ${undoArrayState[index].column}`,
          open: true,
        }));
        console.log(error)
      }
    ).finally(() => {
      setLock(false);
      dispatch(setExecuteUndoRedo(false));
      dispatch(setRefreshData(true));
    });
    
  }, [serverUrl, updateCellValue, undoArrayState, dispatch]);
  
  React.useEffect(() => {
    if (disableButtons) {
      setLock(true);
    }
    
    if (!disableButtons) {
      setLock(false);
    }
    
  }, [disableButtons]);
  
  return(
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        m:5,
        gap: 10
      }}
    >
      <ButtonWithList
        arrayList={undoArray}
        actionLabel={"Undo"}
        keyEventArray={'z'}
        disabled={lock || undoArrayState.length === 0}
        placement={"bottom-start"}
        icon={(lock || undoArrayState.length === 0) ? <UndoIcon /> : <UndoIcon color={"primary"}/>}
        onClickMain={() => {handleUndo(undoArrayState.length-1)}}
        selectedCondition={"selectedGTItem"}
        onClickListItem={(index) => {handleUndo(index)}}
        />
      <ButtonWithList
        arrayList={redoArray}
        actionLabel={"Redo"}
        keyEventArray={'y'}
        disabled={lock || redoArrayState.length === 0}
        placement={"bottom-start"}
        icon={(lock || redoArrayState.length === 0) ? <RedoIcon /> : <RedoIcon color={"primary"}/>}
        onClickMain={() => handleRedo(redoArrayState.length-1)}
        selectedCondition={"selectedGTItem"}
        onClickListItem={(index) => {handleRedo(index)}}
        />
    </Box>
  )
}

export default UndoRedoControl;