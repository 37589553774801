import React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';

interface PropsMainIconButton {
  typeButton: "submit" | "button";
  size?: "small" | "medium" | undefined;
  disabled: boolean;
  testId: string;
  children: React.ReactNode;
  onClick?: () => void;
  keyEvent?: string;
}

const IconButton: React.FC<PropsMainIconButton> = (props: PropsMainIconButton) => {
  
  React.useEffect(() => {
    
    if (props.disabled) {
      return
    }
    
    const handleKeyDown = (event: KeyboardEvent) => {
      if (props.keyEvent && props.keyEvent === event.key && event.ctrlKey) {
        props.onClick && props.onClick()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    
  }, [props, props.keyEvent])
  
  return (
    <MuiIconButton
      type={props.typeButton}
      disabled={props.disabled}
      size={props.size}
      data-testid={props.testId}
      onClick={props.onClick}
    >
      {props.children}
    </MuiIconButton>
  )
}

export default IconButton;