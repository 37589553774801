import * as React from 'react';
import {
  Container,
  Grid,
  Paper,
} from "@mui/material";
import TaskLog from "@/pages/TaskManager/TaskLog/TaskLog";
import { MainLayout } from "@/components/Layout";
import PipelineLayout from "@/pages/TaskManager/PipelineDetails/PipelineLayout";

function TaskManager() {

  return (
    <MainLayout>
      <Container maxWidth={false} sx={{py: 3}}>
        <Paper elevation={0}>
          <Grid container direction='row'>
            <Grid
              item xs={4}
              sx={{
                px: 4,
                py: 1,
              }}
            >
              <PipelineLayout/>
            </Grid>
            <Grid item xs={8} sx={{
              px: 4,
              py: 1,
            }}>
              <TaskLog />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </MainLayout>
  )
}

export default TaskManager;
