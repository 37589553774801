import {createSlice} from '@reduxjs/toolkit';
import {RootState} from "@/store";
import { AlertAttr } from "@/utils/types/store/alert/alert";

interface AlertState {
  alerts: AlertAttr[],
}

const initialState: AlertState = {
  alerts: []
}


const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action) => {
      state.alerts.push(action.payload);
    },
    closeAlert: (state, action) => {
      const id = action.payload;
      state.alerts[id].open =false;
    }
  }
})

export const {addAlert, closeAlert} = alertsSlice.actions;
export default alertsSlice.reducer;
export const selectAlerts = (state: RootState) => state.alerts.alerts;