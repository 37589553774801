import { createTheme } from "@mui/material/styles";


declare module '@mui/material/styles' {
  interface Palette {
    wartsilaAppBar: Palette['primary'];
  }
  interface PaletteOptions {
    wartsilaAppBar: PaletteOptions['primary'];
  }
}

const themeMui = createTheme({
  spacing: 2,
  palette: {
    primary: {
      main: "#2ba8d9",
    },
    secondary: {
      main: "#b0a5a5",
    },
    wartsilaAppBar: {
      main: "#a1571b",
    }
  },
});

export default themeMui;