import { CountryCode, OwnerCountryCode, ChartType } from "@/pages/s57-tx97/DataGrid/ColumnValues";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { containsCyrillic, containsWhiteSpaces, containsOnlySpaces } from "@/utils/helpers/text/textValidation";
import { GridCellParams, GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid';
import DataGridValidationEditor from "@/components/DataGrid/Editors/DataGridValidationEditor";
import clsx from "clsx";


const getDataSource = (type: { [key: number] : string }): {value: number, label: string}[] => {
  return Object.entries(type)
    .map(([key, val]) => { return { value: Number(key), label: val }; })
  };

const addPadStart = (d: number): string => {
  return d.toString().padStart(2, '0');
}

const formatDate = (date: Date): string => {
  if (date === undefined || date === null) {
    return '';
  }
  return date.getFullYear() + "-" + addPadStart(date.getMonth() + 1) + "-" + addPadStart(date.getDate());
}

const columnsENCP: GridColDef[] = [
  {
    field: "Mark",
    headerName: "Mark",
    type: "boolean",
    editable: true,
    width: 50,
  },
  {
    field: "Tx97",
    headerName: "S57->Tx97",
    renderCell(params) {
      return params.value
        ? <CheckCircleIcon style={{color: "#9df294"}}/>
        : <DoNotDisturbOnIcon style={{color: "#f24b29"}}/>;
    },
    type: "boolean",
    editable: true,
    width: 80,
  },
  {
    field: "Name",
    headerName: "S57 Name",
    editable: false,
    type: "string",
    width: 100,
  },
  {
    field: "S57Status",
    headerName: "S57 Status",
    editable: false,
    type: "string",
    width: 100,
    cellClassName: (params: GridCellParams) => {
      if (params.value === 'New') {
        return clsx('super-app', 'green-highlight');
      }
      if (params.value === 'Deleted') {
        return clsx('super-app', 'red-highlight');
      }
      return "super-app";
    },
  },
  {
    field: "EditionDate",
    headerName: "S57 EditionD",
    editable: false,
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => formatDate(value),
    width: 100
  },
  {
    field: "EditionNum",
    headerName: "S57 EditionN",
    editable: false,
    type: "number",
    width: 100
  },
  {
    field: "UpdateDate",
    headerName: "S57 UpdateD",
    editable: false,
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => formatDate(value),
    width: 100
  },
  {
    field: "UpdateNum",
    headerName: "S57 UpdateN",
    editable: false,
    type: "number",
    width: 100
  },
  {
    field: "Scale",
    headerName: "S57 Scale",
    editable: false,
    type: "number",
    width: 80
  },
  {
    field: "Title",
    headerName: "S57 Title",
    editable: false,
    type: "string",
    width: 140,
  },
  {
    field: "OutName",
    headerName: "TX97 Name",
    editable: true,
    type: "string",
    width: 100,
    renderEditCell: (params: GridRenderEditCellParams) =>
      <DataGridValidationEditor
        key="tx97-name-editor"
        cellParams={params}
        condition={(value: string) => {
          return value === null || (value.length <= 10 && !containsCyrillic(value) && !containsWhiteSpaces(value))
        }}
      />,
  },
  {
    field: "Status",
    headerName: "Status in wheel",
    type: "singleSelect",
    width: 130,
    valueOptions: ["Build required", "Up-to-date"],
    cellClassName: (params: GridCellParams) => {
      if (params.value === 'Build required') {
        return clsx('super-app', 'green-highlight');
      }
      return "super-app";
    },
  },
  {
    field: "PaperName",
    headerName: "Paper Name",
    editable: true,
    type: "string",
    width: 100,
    renderEditCell: (params: GridRenderEditCellParams) =>
      <DataGridValidationEditor
        key="paper-name-editor"
        cellParams={params}
        condition={(value: string) => {
          return value === null || (value.length <= 10 && !containsCyrillic(value) && !containsWhiteSpaces(value))
        }}
      />,
  },
  {
    field: "EncAddOutScale",
    headerName: "Tx97 Scale",
    editable: true,
    type: "number",
    width: 90,
    renderEditCell: (params: GridRenderEditCellParams) =>
      <DataGridValidationEditor
        key="tx97-scale-editor"
        cellParams={params}
        condition={(value: string) => {
          return value === null || (!isNaN(Number(value)) && !containsWhiteSpaces(value))
        }}
      />,
  },
  {
    field: "Curves",
    headerName: "Closed Isobaths",
    editable: true,
    type: "string",
    width: 100,
    renderEditCell: (params: GridRenderEditCellParams) =>
      <DataGridValidationEditor
        key="curves-editor"
        cellParams={params}
        condition={(value: string) => {
          return value === '' || /^[0-9.,]+$/.test(value)
        }}
      />,
  },
  {
    field: "EncAddTitle",
    headerName: "TX97 Title",
    editable: true,
    type: "string",
    width: 100,
    renderEditCell: (params: GridRenderEditCellParams) =>
      <DataGridValidationEditor
        key="tx97-title-editor"
        cellParams={params}
        condition={(value: string) => {
          return !containsCyrillic(value) && !containsOnlySpaces(value)
        }}
      />,
  },
  {
    field: "Issue",
    headerName: "Issue",
    editable: false,
    type: "string",
    width: 75,
  },
  {
    field: "OutType",
    headerName: "Chart Type",
    editable: true,
    type: "singleSelect",
    width: 100,
    valueOptions: getDataSource(ChartType),
  },
  {
    field: "OutCntr",
    headerName: "Producer code",
    width: 120,
    editable: true,
    type: "singleSelect",
    valueOptions: getDataSource(CountryCode),
  },
  {
    field: "OutCntG",
    headerName: "Country Owner",
    width: 120,
    editable: true,
    type: "singleSelect",
    valueOptions: getDataSource(OwnerCountryCode),
  },
  {
    field: "Notes",
    headerName: "Notes",
    type: "string",
    editable: true,
    width: 100,
    renderEditCell: (params: GridRenderEditCellParams) =>
      <DataGridValidationEditor
        key="notes-editor"
        cellParams={params}
        condition={(value: string) => true}
      />,
  },
  {
    field: "PublicationName",
    headerName: "Publication name",
    editable: false,
    type: "string",
    width: 100
  },
  {
    field: "PublicationDatetime",
    headerName: "Publication date",
    editable: false,
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => formatDate(value),
    width: 100
  },
  {
    field: "Storage",
    editable: false,
    type: "string",
    width: 100
  }
]

export default columnsENCP