import React from "react";
import {Task} from "@/utils/types/store/tasks/backendAPI";
import {useTypedSelector} from "@/store";
import { selectStatus } from "@/store/tasks/requestTasks";
import { timeDateAsReadableString } from "@/utils/helpers";
import {Typography, Box} from "@mui/material";

interface TaskInfoProps {
  taskData: Task[]
}

const TaskInfo: React.FC<TaskInfoProps> = ( props: TaskInfoProps) => {
  
  const status = useTypedSelector(selectStatus);
  
  const dateTaskStarted = React.useMemo(() => {
    return props.taskData[0].time_started ? timeDateAsReadableString(props.taskData[0].time_started) : null;
  }, [props]);
  
  return (
    <Box>
      <Typography variant="body1" component="p" gutterBottom>
        Name: {props.taskData[0].tag}
      </Typography>
      <Typography variant="body1" component="p" gutterBottom>
        Created by: {props.taskData[0].created_by}
      </Typography>
      <Typography variant="body1" component="p" gutterBottom>
        {dateTaskStarted && `Time started: ${dateTaskStarted}` }
      </Typography>
      <Typography variant="body1" component="p" gutterBottom>
        Status: {status}
      </Typography>
    </Box>
  )
  
}

export default TaskInfo;