import React from "react";
import Box from "@mui/material/Box";
import AlertBox from "@/components/Notifications/AlertBox";

interface MainLayoutProps {
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = (props: MainLayoutProps) => {
  return (
    <main>
      <AlertBox />
      <Box sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: props.direction || "row",
        position: "relative",
        height: "calc(100vh - 42px)",
      }}>
        {props.children}
      </Box>
    </main>
  )
}

export default MainLayout;