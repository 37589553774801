import { MainLayout } from "@/components/Layout";
import EnncmDataGrid from "./DataGrid/DataGrid";
import ControlForm from "@/pages/s57-tx97/ControlForm/ControlForm";

const S57_TX97 = () => {
  return (
    <MainLayout
      direction="column"
    >
      <ControlForm />
      <EnncmDataGrid />
    </MainLayout>
  )
}

export default S57_TX97