import Modal from "./Modal";
import { Box } from "@mui/material";
import { Button } from '@/components/Buttons';
import React, {useCallback} from "react";

const styleModalButtons = {
  display: "flex",
  justifyContent: "flex-end",
  m: 4,
  gap: 5 
}

const buttonStyle = {
  width: 100,
  background: 'linear-gradient(#FAFAFA, #CFD4E0)'
}

interface ModalWindowProps {
  onOkClick: () => void;
  open: boolean;
  setOpen: (modalView: boolean) => void;
  okButtonTitle: string;
  cancelButtonTitle?: string;
  testId?: string;
  children?: React.ReactElement;
  modalTitle: string;
}

const ModalOkCancelWindow: React.FC<ModalWindowProps> = (
  {
    onOkClick,
    okButtonTitle,
    cancelButtonTitle = "Cancel",
    open,
    setOpen,
    testId,
    children,
    modalTitle,
  } : ModalWindowProps
) => {
  
  const [openModal, setOpenModal] = React.useState<boolean>(open);
  
  const handleClose = useCallback(() => {
    setOpenModal(false);
    setOpen(false);
  },[setOpen])
  
  React.useEffect(() => {
    setOpenModal(open)
  }, [open])

  return(
    <Modal
      open={openModal}
      onClose={handleClose}
      modalTitle={modalTitle}
      testId={testId}
    >
      <>
        {children}
        <Box sx={styleModalButtons}>
          <Button
            disabled={false}
            testId={"test-ok-button"}
            typeButton='button'
            onClick={() => {
              onOkClick();
              handleClose();
            }}
            style={buttonStyle}
          >
            {okButtonTitle}
          </Button>
          <Button
            disabled={false}
            testId={"test-cancel-button"}
            typeButton='button'
            onClick={handleClose}
            style={buttonStyle}
          >
            {cancelButtonTitle}
          </Button>
        </Box>
      </>
    </Modal>
  )
}

export default ModalOkCancelWindow;