import Filter from '@/utils/types/service/filters'

export const daysArray: Filter[] =
  [
    {id: 0, displayName: '7 days', value: "7"},
    {id: 1, displayName: '24 hours', value: "1"},
    {id: 2, displayName: '48 hours', value: "2"},
    {id: 3, displayName: '30 days', value: "30"},
    {id: 4, displayName: '90 days', value: "90"},
    {id: 5, displayName: 'All', value: "all"},
  ]

export const buildsArray: Filter[] =
  [
    {id: 0, displayName: 'All', value: 'all'},
    {id: 1, displayName: 'UTT WMS', value: 'UTT_WMS'},
    {id: 2, displayName: 'UTT WMS Air', value: 'UTT_AIR'},
    {id: 3, displayName: 'WFS prod', value: 'WFS'},
    {id: 4, displayName: 'WFS TEST', value: 'WFS_test'},
    {id: 5, displayName: 'TX Atlas', value: 'tx_atlas'},
    {id: 6, displayName: 'TX Preview', value: 'tx_preview'},
    {id: 7, displayName: 'TX one to one', value: 'TX_ONE_TO_ONE'},
  ]

export const statusArray: Filter[] = [
  {id: 0, displayName: 'All', value: 'all'},
  {id: 1, displayName: 'Failed', value: 'Failed'},
  {id: 2, displayName: 'In progress', value: 'In progress'},
  {id: 3, displayName: 'Pending', value: 'Pending'},
  {id: 4, displayName: 'Canceled', value: 'Canceled'},
  {id: 5, displayName: 'Finished', value: 'Finished'},
  {id: 6, displayName: 'Unknown', value: 'Unknown'},
]