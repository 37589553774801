import { createSlice } from "@reduxjs/toolkit";
import {RootState} from "@/store";

interface ActionsData {
  undo: Array<{
    name: string,
    column: string,
    prevValue: string,
    nextValue: string,
  }>;
  redo: Array<{
    name: string,
    column: string,
    prevValue: string,
    nextValue: string,
  }>
}

const initialState: ActionsData = {
  undo: [],
  redo: []
}

const actionsManager = createSlice({
  name: 'actionsManager',
  initialState,
  reducers: {
    setUndo: (state, action) => {
      if (state.undo.length === 10) {
        state.undo.shift();
      }
      state.undo.push(action.payload);
    },
    setRedo: (state, action) => {
      if (state.redo.length === 10) {
        state.redo.shift();
      }
      state.redo.push(action.payload);
    },
    setReduceLastUndo: (state) => {
      state.undo.pop();
    },
    setReduceLastRedo: (state) => {
      state.redo.pop();
    },
    setRedoNull: (state) => {
      state.redo = [];
    }
  }
})


export const {
  setUndo,
  setRedo,
  setReduceLastUndo,
  setReduceLastRedo,
  setRedoNull
} = actionsManager.actions;

export default actionsManager.reducer;

export const selectActionsManagerUndo = (state: RootState) => state.actionsManager.undo;
export const selectActionsManagerRedo = (state: RootState) => state.actionsManager.redo;
