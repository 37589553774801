import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setServerUrl, setServerName, selectServerUrl, selectServerName} from "@/store/authBE/backendServerUrl";
import serverUrlList from "@/service/BackEnd/server";
import {List} from "@mui/material";
import { ListButton } from '@/components/Buttons';


const ConfigBackEnd = () => {

  const dispatch = useDispatch();
  const serverName = useSelector(selectServerName);
  const url = useSelector(selectServerUrl);

  const [server, setServer] = React.useState<{
    name: string,
    url: string
  }>({
    name: serverName,
    url: url,
  });

  React.useEffect(() => {
    dispatch(setServerUrl(server.url));
    dispatch(setServerName(server.name));
  }, [server, dispatch])

  return (
    <List sx={{m:0, p:0, width: "100%", "&:hover": {backgroundColor: "transparent", }}}>
      {
        Object.entries(serverUrlList).map((item) => (
          <ListButton
            key={item[0]}
            name={item[0]}
            value={item[1]}
            selected={server.name === item[0]}
            handleClickEvent={(value) => {
              setServer({
                name: item[0],
                url: value
              })}
            }
          />
        ))
      }
    </List>
  )
}

export default ConfigBackEnd;