import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from "@/service/BackEnd/customFetch/customFetchBase";
import HealthCheck from "@/utils/types/store/general/healthCheck";

export const generalApi = createApi({
  reducerPath: 'General/api',
  baseQuery: customFetchBase,
  endpoints: build => ({
    getServerHealth: build.query<any, HealthCheck>({
      query: (HealthCheck) =>({
        url: `${HealthCheck.serverUrl}/health`,
        method: 'GET'
      })
    }),
  })
});

export const {useGetServerHealthQuery} = generalApi;