import {CssBaseline, ThemeProvider} from "@mui/material";
import themeMui from "@/utils/themes/themeMui";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "@/components/Layout";
import { RoutesComponent } from "@/components/routes/routesApps";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '@/store';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_LICENSE_KEY } from "@/utils/constants";

function App() {
  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY as string);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={themeMui}>
          <CssBaseline />
          <Router>
            <Layout>
              <AuthenticatedTemplate>
                <RoutesComponent />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate />
            </Layout>
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

