import {getPercentage} from "@/utils/helpers";
import { Task }  from "@/utils/types/store/tasks/backendAPI";


type ProgressProps = Pick<Task, "progress_current" | "progress_total" | "estimation" | "time_started">

const getTaskProgress = ({progress_current, progress_total, estimation, time_started} : ProgressProps) : string => {
  if (progress_current && progress_total) {
    return getPercentage(progress_current, progress_total, 1);
  }
  let duration;
  if (time_started) {
    duration = (Date.now() - new Date(time_started).getTime()) / 1000;
  }
  if (!estimation || !duration || estimation === 0 || duration === 0 || duration > estimation) {
    return '99';
  }
  
  return getPercentage(duration, estimation, 1);
}

export default getTaskProgress;