import React from "react";
import { useMsal } from "@azure/msal-react";
import {ListItemIcon, MenuItem} from "@mui/material";
import {Logout} from "@mui/icons-material";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
     instance.logoutRedirect({
        postLogoutRedirectUri: "/",
     });
  };

  return (
    <MenuItem onClick={() => handleLogout()}>
      <ListItemIcon>
        <Logout fontSize="small" />
      </ListItemIcon>
      Logout
    </MenuItem>

  )
};