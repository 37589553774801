import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from "@/service/BackEnd/customFetch/customFetchBase";
import RequestUpdateCellValue from "@/utils/types/store/converter/requestUpdateCellValue";
import RequestENCCellDataServer from "@/utils/types/store/converter/requestENCCellData";
import ResponseENCCellData from "@/utils/types/store/converter/responeENCCellData";
import StartTXPipeline from "@/utils/types/store/converter/startTXPipeline";
import AvcsInfoResponse from '@/utils/types/store/converter/avcsInfo';

// in future add here methods for all requests to backend for work with converter

export const converterApi = createApi({
  reducerPath: 'Converter/api',
  baseQuery: customFetchBase,
  endpoints: build => ({
    filterENCCellTable: build.mutation<ResponseENCCellData, RequestENCCellDataServer>({
      query: (requestBody: RequestENCCellDataServer) => ({
        url: `${requestBody.serverUrl}/repo/api/tx_one_to_one`,
        method: 'POST',
        body: requestBody.request
      }),
    }),
    updateCellValue: build.mutation<void, RequestUpdateCellValue>({
      query: (requestParams: RequestUpdateCellValue) => ({
        url: `${requestParams.serverUrl}/repo/api/tx_one_to_one/patch_table`,
        method: 'PATCH',
        body: requestParams.requestBody
      }),
    }),
    startConversion: build.mutation<void, StartTXPipeline>({
      query: (requestBody: StartTXPipeline) => ({
        url: `${requestBody.serverUrl}/builder/api/build/tx_one_to_one`,
        method: 'POST',
        body: {
          chart_names: requestBody.chartNames
        }
      }),
    }),
    publishPipeline: build.mutation<void, StartTXPipeline>({
      query: (requestBody: StartTXPipeline) => ({
        url: `${requestBody.serverUrl}/builder/api/publish/tx_one_to_one`,
        method: 'POST',
        body: {
          chart_names: requestBody.chartNames
        }
      })
    }),
    getLastAVCSIssue: build.query<AvcsInfoResponse, string>({
      query: (serverUrl: string) => ({
        url: `${serverUrl}/repo/api/tx_one_to_one/last_avcs_update_info`,
        method: 'GET'
      })
    })
  }),
});

export const {
  useFilterENCCellTableMutation,
  useUpdateCellValueMutation,
  useStartConversionMutation,
  usePublishPipelineMutation,
  useGetLastAVCSIssueQuery
} = converterApi;