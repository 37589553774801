import ValidationEditor from '@/components/Text/ValidationEditor';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React from 'react';

interface DataGridValidationProps {
    condition: (value:any) => boolean,
    cellParams: GridRenderEditCellParams
}

const DataGridValidationEditor: React.FC<DataGridValidationProps> =({
    condition, cellParams
}) => {
        const { id, value, field } = cellParams;
        const apiRef = useGridApiContext();
        const initialValue = cellParams.formattedValue;

        const setEditCellValue = (value: any) => {
            apiRef.current.setEditCellValue({ id, field, value: value });
        }

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setEditCellValue(event.target.value);
        }

        const handleOnBlur = () => {
            setEditCellValue(condition(value) ? value : initialValue);
        }

        const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Escape') {
                setEditCellValue(initialValue);
            }
            if (e.key === 'Enter') {
                setEditCellValue(condition(value) ? value : initialValue);
            }
        }

        return <ValidationEditor
                    value={cellParams.value}
                    onChange={handleValueChange}
                    onKeyDown={handleOnKeyDown}
                    condition={condition}
                    onBlur={handleOnBlur}
                />
}

export default DataGridValidationEditor