import React from "react";
import {
  ButtonGroup as MuiButtonGroup,
} from "@mui/material";
import { ButtonGroupProps as MuiButtonGroupProps } from "@mui/material/ButtonGroup";

interface ButtonGroupProps extends MuiButtonGroupProps {
  variant?: "text" | "outlined" | "contained";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium" | "large";
  children: React.ReactNode;
  ariaLabel?: string;
  testId?: string;
}

const ButtonGroup = (props: ButtonGroupProps) => {
  return (
    <MuiButtonGroup
      variant={props.variant || "contained"}
      color={ props.color || "primary"}
      size={props.size || undefined}
      aria-label={props.ariaLabel || undefined}
      data-testid={props.testId || undefined}
    >
      {props.children}
    </MuiButtonGroup>
  );
}

export default ButtonGroup;