import ServerBackendUrlList from "@/utils/types/service/server";
import { STAGE_API_URL, PREPROD_API_URL, PROD_API_URL, LOCAL_API_URL } from "@/utils/constants";

const serverUrlList: ServerBackendUrlList = {
  stage: STAGE_API_URL as string,
  preprod: PREPROD_API_URL as string,
  prod: PROD_API_URL as string,
  local: LOCAL_API_URL as string,
}

export default serverUrlList;