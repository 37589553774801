import {createSlice} from '@reduxjs/toolkit';
import {RootState} from "@/store";
import InfoGuid from "@/utils/types/store/tasks/infoTask";

const initialState: InfoGuid = {
  simpleStatus: "",
  simpleGuid: ""
};

const guidSlice = createSlice({
  name: 'informationTask',
  initialState,
  reducers: {
    setSimpleGuid: (state, action) => {
      state.simpleGuid = action.payload;
    },
    setSimpleStatus: (state, action) => {
      state.simpleStatus = action.payload;
    }
  }
});

export const {
  setSimpleGuid,
  setSimpleStatus
} = guidSlice.actions;
export default guidSlice.reducer;
export const selectSimpleGuid = (state: RootState) => state.selectedGuid.simpleGuid;
export const selectSimpleStatus = (state: RootState) => state.selectedGuid.simpleStatus;