import { MainLayout } from '@/components/Layout';
import MainMap from '@/components/map/MainMap';


function MapPage() {

  return (
    <MainLayout>
      <MainMap />
    </MainLayout>
  )
}

export default MapPage;