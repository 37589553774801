import React, { useCallback } from "react"
import { useAppDispatch, useTypedSelector } from "@/store";
import { DataGrid } from "@/components/DataGrid";
import { useFilterENCCellTableMutation, useUpdateCellValueMutation } from "@/store/converter/converterApi";
import { Box, CircularProgress } from "@mui/material";
// Enable to get data from different servers
import {selectServerUrl} from "@/store/authBE/backendServerUrl";
import { gridState, setGridState } from "@/store/converter/gridState";
import columnsENCP from "@/pages/s57-tx97/DataGrid/Columns";
import { addAlert } from "@/store/alerts/alertsSlice";
import { setUndo, setRedoNull } from "@/store/converter/actionsManager";
import { setDisableButtons } from "@/store/converter/redoUndoController";
import { selectCharts, setCharts } from "@/store/converter/listCharts";
import {GridInitialState, GridValidRowModel} from "@mui/x-data-grid-pro";


const ENCPDataGrid = () => {
  
  const selectedCharts = useTypedSelector(selectCharts);
  const dataGrid = useTypedSelector(gridState);
  const dispatch = useAppDispatch();
  
  // Enable to get data from different servers
  const serverUrl = useTypedSelector(selectServerUrl);
  
  const [
    getENCPData,{
      data: dataENCP,
      isLoading: getENCPDataIsLoading,
      isError: getENCPDataIsError,
    }
  ] = useFilterENCCellTableMutation()
  
  const [updateCellValue] = useUpdateCellValueMutation()
  
  const handleUpdateState = useCallback((gridState: GridInitialState) => {
    dispatch(setGridState(gridState))
  }, [dispatch]);
  
  const handleUpdateSelection = useCallback((selectionModel: GridValidRowModel) =>{
    dispatch(setCharts(selectionModel))
  }, [dispatch])
  
  React.useEffect(() => {
    getENCPData({
      serverUrl: serverUrl,
      request: {
        filterBy: [],
        pagination: {
          skip: 0,
          limit: null
        },
        sortInfo: ""
      }
    }).unwrap().then().catch((error) => {
      dispatch(addAlert({
        severity: "error",
        message: `Can't get data ENCCM from ${serverUrl} server, please check VPN connection`,
        open: true,
      }));
      console.error(error)
    })
  }, [serverUrl, getENCPData, dispatch])
  
  return (
    <>
      {getENCPDataIsLoading && <Box sx={
        {
          display: "flex",
          height: "100%",
          weight: "100%",
          alignItems: "center",
          justifyContent: "center"
        }
      }> <CircularProgress /> </Box>}
      { dataENCP && !getENCPDataIsError &&
        <DataGrid
          idProperty="EncCellID"
          gridStyle={{ height: "calc(100vh - 100px)" }}
          columns={columnsENCP}
          rows={dataENCP && dataENCP.data}
          rowID={dataENCP && ((row: any) => {
            return row.EncCellID
          })}
          loading={getENCPDataIsLoading}
          
          // selection
          initialSelection={selectedCharts.map((item: any) => item.EncCellID)}
          updateSelection={handleUpdateSelection}
          
          // work with apiRefState
          initialState={dataGrid.gridState}
          updatedState={handleUpdateState}
          
          // Edit function
          onEditStart={() => {
            dispatch(setDisableButtons(true))
          }}
          onEditCancel={() => {
            dispatch(setDisableButtons(false))
          }}
          onEditComplete={
            (value: string,
             oldValue: string,
             columnId: string,
             newData: {[key: string]: string | number | boolean}
            ) => {
              updateCellValue({
                serverUrl: serverUrl,
                requestBody: {
                  name: newData.Name as string,
                  column: columnId,
                  value: value,
                }
              }).unwrap().then(()=> {
                dispatch(setRedoNull())
                dispatch(setUndo({
                  name: newData.Name as string,
                  column: columnId,
                  prevValue: oldValue as string,
                  nextValue: value,
                }))
              }).catch(
                (error) => {
                  dispatch(addAlert({
                    severity: "error",
                    message: `Something went wrong when updating the value of ${value} in column ${columnId}`,
                    open: true,
                  }));
                  console.log(error)
                }
              )
            }}
        />
      
      }
      
    </>
    
  )
}

export default ENCPDataGrid