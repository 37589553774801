import MapPage from '@/pages/MapPage';
import TaskManager from '@/pages/TaskManager/TasksManager';
import RouteTab from '@/utils/types/service/route';
import S57_TX97 from "@/pages/s57-tx97/s57-tx97";

const routesDict: Array<RouteTab> = [
  {
    key: 'wmsmap-route',
    title: 'WMS Map',
    path: '/',
    enabled: true,
    component: MapPage
  },
  {
    key: 'dashboard-route',
    title: 'Task Manager',
    path: '/TaskManager',
    enabled: true,
    component: TaskManager
  },
  {
    key: 's57-tx97',
    title: 'S57\u21D2TX97',
    path: '/S57-TX97',
    enabled: false,
    component: S57_TX97
  }
]

export default routesDict;