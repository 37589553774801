import updateTokenInStorage from "@/service/Auth/updateToken/updateTokenInStorage";
import Params from "@/utils/types/service/urlparams";
import toQueryString from "@/utils/helpers/download/toQueryString";


export const downloadZip = async(
  url: string,
  endpoint: string,
  method: 'GET' | 'POST' = 'GET',
  params: Params | null = null,
  filename: string
) => {
  
  const token = await updateTokenInStorage();
  
  if (!token) {
    return false;
  }
  
  let fullUrl = `${url}/${endpoint}`;
  let config: {
    method: 'GET' | 'POST',
    headers: {
      Authorization: string,
    },
    body?: string
  } = {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`
    },
  }
  
  if (method === 'GET') {
    const queryParams = params ? toQueryString(params) : '';
    fullUrl = `${fullUrl}${queryParams}`;
  }
  
  if (method === 'POST') {
    const queryParams = JSON.stringify(params);
    config = {
      ...config,
      body: queryParams
    }
  }
  
  const response = await fetch(fullUrl, config);
  
  if (!response.ok) {
    console.log(response.statusText);
    return false;
  }
  
  const blob = await response.blob();
  
  const fileUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  
  link.href = fileUrl
  // because if we want to use the filename from the server we need to add config for django
  // CORS_EXPOSE_HEADERS =['content-disposition']
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(fileUrl);
  
  return true;
}