import React from "react";
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
} from "@mui/material";
import { ToggleButton } from "@mui/material";


interface ToggleButtonGroupProps {
  value: string | null;
  exclusive: boolean;
  onChange: (event: React.MouseEvent<HTMLElement>, newFilter: string | null) => void;
  ariaLabel: string;
  size: "small" | "medium" | "large";
  buttonsToggle: Array<{
    value: string;
    ariaLabel: string;
    dataTestId: string;
    text: string;
    iconElement: React.ReactElement;
  }>;
}

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = (props:ToggleButtonGroupProps) => {
  return (
    <MuiToggleButtonGroup
      value={props.value}
      exclusive={props.exclusive}
      onChange={props.onChange}
      aria-label={props.ariaLabel}
      size={props.size}
    >
      {
        props.buttonsToggle.map((button) => {
          return (
            <ToggleButton
              key={button.value}
              value={button.value}
              aria-label={button.ariaLabel}
              data-testid={button.dataTestId}
            >
              {button.iconElement} {button.text}
            </ToggleButton>
          )
        })
      }
    </MuiToggleButtonGroup>
  )
}

export default ToggleButtonGroup;