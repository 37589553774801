import React from "react";
import { useAppDispatch } from "@/store";
import { useTypedSelector } from "@/store";
import { Grid, Paper, Typography } from "@mui/material";
import { buildsArray, statusArray, daysArray } from "@/service/Tasks/filtersArray";
import { SelectFormControl } from "@/components/Forms";
import { setTag, setStatus } from "@/store/tasks/requestTasks";
import { getTimeByDayBefore } from "@/utils/helpers";
import { selectServerUrl } from "@/store/authBE/backendServerUrl";
import { useGetTasksByTimeQuery } from "@/store/tasks/tasksAPI";
import RequestTaskParameters from "@/utils/types/store/tasks/requestTasks";
import checkPipelineStatus from "@/service/Tasks/CheckPipelineStatus";
import { IconButton } from "@/components/Buttons";
import RefreshIcon from "@mui/icons-material/Refresh";
import {addAlert} from "@/store/alerts/alertsSlice";


const FiltersControlForm = () =>{
  
  const dispatch = useAppDispatch();
  
  const [ build, setBuild ] = React.useState<string>('all');
  const [ taskStatus, setTaskStatus ] = React.useState<string>('all');
  const [ days, setDays ] = React.useState<string>(daysArray[0].value);
  const [ taskTag, setTaskTag ] = React.useState<string>('');
  
  // work with Tags
  const serverUrl = useTypedSelector(selectServerUrl);
  
  
  const age = React.useMemo(() => {
    const nowTime = new Date();
    if (days==='all') {
      return ""
    }
    return Math.trunc(getTimeByDayBefore(nowTime, parseInt(days))/1000).toString();
  }, [days]);
  
  const queryParam: RequestTaskParameters = {
    serverUrl: serverUrl,
    age: age,
    tag: '',
    updated: ''
  }
  
  const {
    data: tasksData,
    isLoading,
    isError,
    refetch,
  } = useGetTasksByTimeQuery(queryParam,
    {
      pollingInterval: 60000,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );
  
  const tagsArray = React.useMemo(() => {
    if (!tasksData) {
      return undefined
    }
    
    const buildArray = build !== 'all'
      ? tasksData.filter((task) => task.tag.includes(build))
      : [...tasksData];
    
    const uniqueTags = buildArray.map((task) => task.tag).filter((value, index, self) => self.indexOf(value) === index);
    
    const statusArray = taskStatus !== 'all'
      ? uniqueTags.filter((tag) => {
        const tagArray = tasksData.filter((task) => task.tag === tag);
        return taskStatus === checkPipelineStatus(tagArray)})
      : uniqueTags;
    
    if (statusArray.length === 0) {
      return undefined
    }
    
    if (!statusArray.includes(taskTag)) {
      setTaskTag(statusArray[0]);
    }
    
    return statusArray.map((task, index) => {
      return {
        id: index,
        value: task,
        displayName: task,
      }
    })
  }, [tasksData, build, taskStatus, taskTag]);
  
  // change days
  const daysChange = React.useCallback((value: string) => {
    if (value === 'all') {
      setDays("all");
      return
    }
    setDays(value);
  },[])
  
  // change builds
  const buildsChange = React.useCallback((value: string) => {
    setBuild(value);
  }, []);
  
  // change status
  const statusChange = React.useCallback((value: string) => {
    setTaskStatus(value)
  }, []);
  
  const tagChange = React.useCallback((value: string) => {
    setTaskTag(value);
    dispatch(setTag(value));
  }, [dispatch]);
  
  
  React.useEffect(() => {
    if (!tasksData) {
      return
    }
    
    if(isError){
      dispatch(addAlert({
        message: "Can't get tasks data, please check VPN connection",
        severity: "error",
        open: true
      }));
      return
    }
    
    dispatch(setTag(taskTag));
    dispatch(setStatus(checkPipelineStatus(tasksData.filter((task) => task.tag === taskTag))))
    
  }, [dispatch, tasksData, taskTag, isError]);
  
  return (
    <Grid
      container
      direction="row"
      spacing={2}
      >
      <Grid item xs={10} md={6}>
        <Paper elevation={2}>
          <SelectFormControl
            options={{
              size: 'small',
              fullWidth: true,
            }}
            label={'Builds'}
            defaultValue={build}
            onChange={buildsChange}
            values={buildsArray}
            />
        </Paper>
      </Grid>
      <Grid
        item xs={2} alignItems={'center'} justifyContent={'center'}
        sx={{
          display: {xs: 'flex', md: 'none'}
        }}
        >
        <Paper elevation={2}>
          <IconButton
            typeButton={"button"}
            disabled={false}
            testId={"refetch"}
            onClick={refetch}
            >
            <RefreshIcon />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper elevation={2}>
          <SelectFormControl
            options={{
              size: 'small',
              fullWidth: true,
            }}
            label={'Days'}
            defaultValue={days}
            onChange={daysChange}
            values={daysArray}
            />
        </Paper>
      </Grid>
      <Grid
        item md={1} alignItems='center' justifyContent='center' sx={{
          display: {xs: 'none', md: 'flex', mx: '0px', px: '0px'}
        }}
      >
        <Paper elevation={2}>
          <IconButton
            typeButton={"button"}
            disabled={false}
            testId={"refetch"}
            size={"small"}
            onClick={refetch}
          >
            <RefreshIcon />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={2}>
          <SelectFormControl
            options={{
              size: 'small',
              fullWidth: true,
            }}
            label={'Status'}
            defaultValue={taskStatus}
            onChange={statusChange}
            values={statusArray}
            />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={2}>
          {
            isLoading &&
            <Typography sx={{ height: '40px' }}> Loading... </Typography>
          }
          {
            isError &&
            <Typography sx={{ height: '40px' }}> Something went wrong </Typography>
          }
          {
            !isLoading && !isError && !tagsArray &&
            <SelectFormControl
              options={{
                size: 'small',
                fullWidth: true,
              }}
              label={'Tags'}
              defaultValue={"No data"}
              disabled={true}
              values={ [{
                id: 0,
                value: "No data",
                displayName: "No data",
              }] }
            />
          }
          { !isLoading && tagsArray &&
            <SelectFormControl
              options={{
                size: 'small',
                fullWidth: true,
              }}
              label={'Tags'}
              defaultValue={taskTag}
              onChange={tagChange}
              values={tagsArray}
            />
          }
        </Paper>
      </Grid>
    </Grid>
  )
}


export default FiltersControlForm;