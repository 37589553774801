import { createSlice } from "@reduxjs/toolkit";
import {RootState} from "@/store";
import {GridValidRowModel} from "@mui/x-data-grid-pro";

interface ListCharts {
  execute: boolean,
  charts: GridValidRowModel
}

const initialState: ListCharts = {
  execute: false,
  charts: []
}

const listCharts = createSlice({
  name: 'listCharts',
  initialState,
  reducers: {
    setCharts: (state, action) => {
      state.charts = action.payload;
    },
    setExecute: (state, action) => {
      state.execute = action.payload;
    }
  }
})

export const {setCharts, setExecute} = listCharts.actions;
export default listCharts.reducer;
export const selectCharts = (state: RootState) => state.listCharts.charts;
export const selectExecute = (state: RootState) => state.listCharts.execute;