import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
// import { Pagination, SortInfo } from "@/utils/types/store/converter/requestENCCellData";
//import { GridInitialState } from "@mui/x-data-grid-pro";
// import filterENCP from "@/store/converter/initialFilter";
import initialStateDataGrid from "@/pages/s57-tx97/DataGrid/initialStateDataGrid";


const initialState = {
  gridState: initialStateDataGrid
};

const dataGrid = createSlice({
  name: 'dataGrid',
  initialState,
  reducers: {
    setGridState: (state, action) => {
      state.gridState = action.payload;
    }
  }
})

export const {
  setGridState
} = dataGrid.actions;
export default dataGrid.reducer;
export const gridState = (state: RootState) => state.gridState;
