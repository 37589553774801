import React from "react";
import {
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { AlertAttr } from "@/utils/types/store/alert/alert";
import {useAppDispatch} from "@/store";
import {closeAlert} from "@/store/alerts/alertsSlice";

const ActionAlert: React.FC<AlertAttr & {id: number}> = (
  {
    id,
    severity="info",
    message,
    open,
    collapseTime
  } : AlertAttr & {id: number}) => {
  
  const dispatch = useAppDispatch()
  const [openAlert, setOpenAlert] = React.useState<boolean>(open)
  
  const handleCloseAction = React.useCallback(() => {
    setOpenAlert(false)
    dispatch(closeAlert(id))
  }, [dispatch, id])
  
  React.useEffect(() => {
    if (collapseTime && openAlert) {
      const timer = setTimeout(() => {
        setOpenAlert(false);
      }, collapseTime)
      return () => clearTimeout(timer);
    }
  }, [openAlert, collapseTime]);
  
  return  (
    <Collapse
      in={openAlert}
    >
      <Alert
        sx={{
          my: 1,
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleCloseAction}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        variant="standard"
        severity={severity}>
        {message}
      </Alert>
    </Collapse>
  )
}

export default ActionAlert