import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@/App';
import {msalInstance} from "@/service/Auth/Main/config/msalConfig";
import {MsalProvider} from "@azure/msal-react";



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);
