import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "@/store/index";
import LogFilters from "@/utils/types/store/tasks/taskLogFilter";

const initialState: LogFilters = {
  taskLogFilter: null
}

const taskLogFilterSlice = createSlice({
  name: 'taskLogFilter',
  initialState,
  reducers: {
    setTaskLogFilter: (state, action) => {
      state.taskLogFilter = action.payload;
    }
  }
});

export const {setTaskLogFilter} = taskLogFilterSlice.actions;
export default taskLogFilterSlice.reducer;
export const selectTaskLogFilter = (state: RootState) => state.taskLogFilter.taskLogFilter;