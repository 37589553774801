import React, {useCallback, useEffect} from 'react';
import { useAppDispatch, useTypedSelector } from "@/store";
import { selectCharts } from "@/store/converter/listCharts";
import {Box, Typography} from '@mui/material';
import { Button } from '@/components/Buttons';
import ModalOkCancelWindow from '@/components/ModalWindow/ModalOkCancelWindow';
import { useStartConversionMutation, usePublishPipelineMutation } from '@/store/converter/converterApi';
import { selectServerUrl } from "@/store/authBE/backendServerUrl";
import { addAlert } from "@/store/alerts/alertsSlice";
import { downloadZip } from "@/service/Download/downloadZip";
import { useGetLastAVCSIssueQuery } from '@/store/converter/converterApi';
import UndoRedoControl from '@/pages/s57-tx97/ControlForm/StateButton/UndoRedoControl';

const modalWindowStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 2,
  maxHeight: 500,
  overflowY: "auto",
  m: 4,
  borderRadius: 1,
  backgroundColor: "rgba(153,161,170,0.11)"
}

const buttonStyle = {
  background: 'linear-gradient(#FAFAFA, #CFD4E0)'
}

const ControlForm = () => {
  
  // work with store
  
  const dispatch = useAppDispatch()
  
  const charts = useTypedSelector(selectCharts)
  
  const serverUrl = useTypedSelector(selectServerUrl)
  
  // states
  
  const [buildWindowOpen, setBuildWindowOpen] = React.useState(false)

  const [publishWindowOpen, setPublishWindowOpen] = React.useState(false)
  
  // work with api
  
  const [ startConversion ] = useStartConversionMutation()
  
  const [ publishPipeline ] = usePublishPipelineMutation()

  const { data: avcsData, isError } = useGetLastAVCSIssueQuery(serverUrl);

  const selectedCharts = React.useMemo(() =>{
    const array = Object.values(charts)
    if (array.length > 0) {
      return array.map((item ) => {
        return ({
          name: item.Name,
          storage: item.Storage
        })
      })
    } else {
      return []
    }
  }, [charts])
  
  // handlers
  
  // handlers
  
  const handleClickBuild = React.useCallback(() => {
    if (selectedCharts.length === 0){
      dispatch(addAlert({
        severity: "info",
        message: "Please, select charts",
        open: true,
      }))
      return
    }
    
    setBuildWindowOpen(true);
    
  }, [selectedCharts, dispatch])
  
  const handleOptionalCheck = useCallback(() => {
    
    if (selectedCharts.length === 0) {
      dispatch(addAlert({
        severity: "info",
        message: "Please, select charts",
        open: true,
      }))
      return false
    }
    
    if (selectedCharts.filter((item) => item.storage !== null).length === 0) {
      dispatch(addAlert({
        severity: "info",
        message: "None of the selected charts was built",
        open: true,
      }))
      return false
    }
    
    return true
  }, [dispatch, selectedCharts])
  
  const handleClickDownload = React.useCallback(() => {
    if (!handleOptionalCheck()) {
      return
    }
    
    if (selectedCharts.filter((item) => item.storage === null).length > 0) {
      dispatch(addAlert({
        severity: "warning",
        message: `${selectedCharts.filter((item) => item.storage === null).map(item => item.name).join(", ")} was not built and will not be downloaded`,
        open: true,
      }))
    }
    
    // some action with download
    
    const downloadCharts = selectedCharts.filter((item) => item.storage !== null).map(item => item.name)
    const queryParam = {chart_names: downloadCharts as string[]}
    
    const downloadTxZip = async () => {
      const fileDownloadResult = await downloadZip(
        serverUrl,
        'repo/api/tx_one_to_one/download_build_tx',
        'GET',
        queryParam,
        'tx97.zip'
      )
      if (!fileDownloadResult) {
        dispatch(addAlert({
          severity: "error",
          message: "Error download TX",
          open: true,
        }))
      }
    };
    
    downloadTxZip();
    
  }, [selectedCharts, dispatch, serverUrl, handleOptionalCheck])
  
  const handleClickPublish = React.useCallback(() => {
    if (!handleOptionalCheck()) {
      return
    }
    
    setPublishWindowOpen(true)
  }, [handleOptionalCheck])

  const onBuildClick=(() => {

    startConversion({
      serverUrl: serverUrl,
      chartNames: selectedCharts.map(item =>  item.name) as string[]
    })
    
    dispatch(addAlert({
      severity: "success",
      message: "The conversion process has started",
      open: true,
    }));
  })

  const onPublishClick=(() => {

    publishPipeline({
      serverUrl: serverUrl,
      chartNames: selectedCharts.filter((item) => item.storage !== null).map(item => item.name) as string[]
    });

    dispatch(addAlert({
      severity: "success",
      message: "The publishing process has started",
      open: true,
    }));
  })

  useEffect(() => {
    if (isError){
      dispatch(addAlert({
        severity: "error",
        message: `Error get AVCS Issue, please check VPN connection ${serverUrl}`,
        open: true,
      }))
    }
  }, [serverUrl, dispatch, isError])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 5,
          gap: 10
        }}
      >
        <UndoRedoControl/>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mx: 5,
            gap: 10
          }}
          >
          { isError && <Typography>AVCS Issue undefined</Typography> }
          { !isError && avcsData && <Typography>AVCS Issue: {avcsData.last_issue}</Typography> }
          <Typography>
            Selected Charts: {selectedCharts.length}
          </Typography>
          <Button
            typeButton="button"
            disabled={false}
            size={"small"}
            testId="test-start-button"
            onClick={handleClickBuild}
            style={buttonStyle}
          >
            Start Build
          </Button>
          <Button
            typeButton="button"
            disabled={false}
            size={"small"}
            testId="test-dowload-button"
            onClick={handleClickDownload}
            style={buttonStyle}
            >
            Download TX
          </Button>
          <Button
            typeButton="button"
            disabled={false}
            size={"small"}
            testId="test-publish-button"
            onClick={handleClickPublish}
            style={buttonStyle}
          >
            Publish TX
          </Button>
          <ModalOkCancelWindow
            modalTitle="Selected Charts"
            testId="build-modal-window"
            okButtonTitle="Start"
            onOkClick={onBuildClick}
            open={buildWindowOpen}
            setOpen={(modalView) => {
              setBuildWindowOpen(modalView)
            }}
          >
            <Box sx={modalWindowStyle}>
            {
              selectedCharts.map((item, index) => (
                <Typography
                  key={index}
                >
                  {item.name}
                </Typography>
              ))
            }
            </Box>
          </ModalOkCancelWindow>
          <ModalOkCancelWindow
            modalTitle="Publish Charts"
            testId="publish-modal-window"
            okButtonTitle="Publish"
            onOkClick={onPublishClick}
            open={publishWindowOpen}
            setOpen={(modalView) => {
              setPublishWindowOpen(modalView)
            }}
          >
            <Box sx={modalWindowStyle}>
              <Box>
                <Typography variant='h6'>Publish Charts:</Typography>
              {
                selectedCharts.filter((item) => item.storage !== null).map((item, index) => (
                  <Typography variant='body1' key={index}> {item.name} </Typography>
                ))
              }
              </Box>
              { selectedCharts.filter((item) => item.storage === null).length !== 0 &&
                <Box>
                  <Typography variant='h6'>Excluded from Publish:</Typography>
                {
                  selectedCharts.filter((item) => item.storage === null).map((item, index) => (
                    <Typography variant='body1' key={index}> {item.name} </Typography>
                  ))
                }
                </Box>
              }
            </Box>
          </ModalOkCancelWindow>
        </Box>
      </Box>
    </>
  )
}

export default ControlForm