const cyrillicPattern = /[а-яА-ЯЁё]/;

function containsCyrillic(str: string) {
  return cyrillicPattern.test(str);
}

function containsWhiteSpaces(str: string) {
  return /\s/g.test(str);
}

function containsOnlySpaces(str: string) {
  return str != null && str.trim().length === 0;
}

export { containsCyrillic, containsWhiteSpaces, containsOnlySpaces };