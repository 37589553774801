import { createSlice } from "@reduxjs/toolkit";
import {RootState} from "@/store";


interface undoRedoController {
  executeUndoRedo: boolean;
  disableButtons: boolean;
  refreshData: boolean;
}

const initialState: undoRedoController = {
  executeUndoRedo: false,
  disableButtons: false,
  refreshData: false
}

const redoUndoController = createSlice({
  name: 'redoUndoController',
  initialState,
  reducers: {
    setExecuteUndoRedo: (state, action) => {
      state.executeUndoRedo = action.payload;
    },
    setDisableButtons: (state, action) => {
      state.disableButtons = action.payload;
    },
    setRefreshData: (state, action) => {
      state.refreshData = action.payload;
    }
  }
})

export const {
  setExecuteUndoRedo,
  setDisableButtons,
  setRefreshData
} = redoUndoController.actions;

export default redoUndoController.reducer;

export const selectExecuteStateUndoRedo = (state: RootState) => state.redoUndoController.executeUndoRedo;

export const selectDisableButtons = (state: RootState) => state.redoUndoController.disableButtons;

export const selectRefreshData = (state: RootState) => state.redoUndoController.refreshData;