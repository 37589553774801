import React, {useCallback} from "react";
import {
  Popper as MuiPopper,
  Grow,
  Paper,
  ClickAwayListener
} from "@mui/material";

interface PopperProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  placement: "bottom" | "left" | "right" | "top" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | undefined;
  closeAction: (open: boolean) => void;
  children: React.ReactElement;
}

const Popper: React.FC<PopperProps> = (props: PopperProps) => {
  
  const handleClose = useCallback((event: Event) => {
    if (
      props.anchorEl &&
      props.anchorEl.contains(event.target as HTMLElement)
    ) {
      return;
    }
    
    props.closeAction(false)
    
  },[props]);
  
  return (
    <MuiPopper
      sx={{
        zIndex: 99,
      }}
      open={props.open}
      anchorEl={props.anchorEl}
      role={undefined}
      placement={props.placement}
      transition
      disablePortal
      >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              {props.children}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </MuiPopper>
  )
}

export default Popper;