import { store } from "@/store";
import {msalInstance} from "@/service/Auth/Main/config/msalConfig";
import {addAlert} from "@/store/alerts/alertsSlice";

// get new token from Azure AD
async function getNewToken() {
  const account = msalInstance.getAllAccounts()[0];
  const accessTokenRequest = {
    account: account,
    scopes: ["6ff5779c-17d2-4172-bc59-24f513a4e2cb/user_impersonation"]
  };
  
  return (msalInstance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
    let accessToken = accessTokenResponse.accessToken;
    let expiresOn = accessTokenResponse.expiresOn?.getTime();
    return {
      token: accessToken,
      expiresOn: expiresOn
    }
  }).catch((error) => {
    console.error(error);
    store.dispatch(addAlert({
      severity: 'error',
      message: 'Error while getting new token, please re-login to the application (logout and login again)',
      open: true
    }))
  }))
}


export { getNewToken }