import {store as storage} from "@/store";
import {getNewToken} from "@/service/Auth/updateToken/getNewToken";
import {setExpiresOn, setToken} from "@/store/authBE/authTokenInf";

const updateTokenInStorage = async() => {
  const tokenExpireOn = storage.getState().auth.expiresOn;
  if (tokenExpireOn && tokenExpireOn > new Date().getTime()) {
    return storage.getState().auth.backendToken;
  }
  
  const tokenInf = await getNewToken();
  if (!tokenInf) {
    return null;
  }
  
  storage.dispatch(setExpiresOn(tokenInf.expiresOn));
  storage.dispatch(setToken(tokenInf.token));
  return tokenInf.token;
}

export default updateTokenInStorage;