import React from 'react';
import { useTypedSelector } from '@/store';
import {selectSimpleGuid, selectSimpleStatus} from "@/store/tasks/infoTask";
import {selectServerUrl} from "@/store/authBE/backendServerUrl";
import {GuidTaskRequest} from "@/utils/types/store/tasks/backendAPI";
import {selectTaskLogFilter} from "@/store/tasks/taskLogFilter";
import {useGetTasksByGuidQuery} from "@/store/tasks/tasksAPI";

import { Grid, Paper, Typography, Box, CircularProgress  } from '@mui/material';
import TaskLogFilters from '@/pages/TaskManager/TaskLog/TaskLogFilter';


const TaskLog = () => {
  const selectedGuid = useTypedSelector(selectSimpleGuid);
  const selectedServerUrl = useTypedSelector(selectServerUrl);
  const selectedFilter = useTypedSelector(selectTaskLogFilter);
  
  const statusTask = useTypedSelector(selectSimpleStatus);
  
  const SelectedTask: GuidTaskRequest = {
    serverUrl: selectedServerUrl,
    guid: selectedGuid,
  }
  
  const {
    data: taskLog,
    isError: isErrorTaskLog,
    isLoading: isLoadingTaskLog,
    isFetching: isFetchingTaskLog
  } = useGetTasksByGuidQuery(SelectedTask, {
    skip: selectedGuid === '',
    refetchOnMountOrArgChange: true,
    pollingInterval: statusTask === 'pending' || statusTask === 'running' ? 5000 : 0
  })
  
  const countError = taskLog?.filter((task: string) => task.includes("ERROR")).length;
  const countWarning = taskLog?.filter((task: string) => task.includes("WARNING")).length;
  const countDebug = taskLog?.filter((task: string) => task.includes("DEBUG")).length;
  
  const tasksValuesList = React.useMemo(() => {
    return taskLog?.filter((task: string) => {
      if (selectedFilter === null) {
        return task
      } else if (selectedFilter === "Info") {
        return (!task.includes("ERROR") && !task.includes("WARNING") && !task.includes("DEBUG"))
      } else {
        return task.includes(selectedFilter.toUpperCase())
      }
    })
  },[selectedFilter, taskLog]);
  
  return (
    <Grid
      container
      spacing={1}
    >
      <Grid item xs={12}>
        <TaskLogFilters errors={countError} warnings={countWarning} debugs={countDebug} />
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={1} >
          {
            isErrorTaskLog &&
            <Typography variant="body1">
              Something goes wrong
            </Typography>
          }
          { (isLoadingTaskLog || isFetchingTaskLog) && <CircularProgress /> }
          {
            !isFetchingTaskLog && tasksValuesList &&
            <Box sx={{height: 'calc(100vh - 160px)', overflowY:"auto", px: "5px", py: "5px"}}>
              {
                tasksValuesList.map((task: string, index: number) => {
                  return (
                    <Typography key={index} variant="body1"> {task} </Typography>
                  )
                })
              }
            </Box>
          }
        </Paper>
      </Grid>
    </Grid>
  )
}

export default TaskLog;