import {Routes, Route} from "react-router-dom";
import React from "react";
import routesDict from "@/service/Routes/routesMap";
import RouteTab from '@/utils/types/service/route';

const RoutesComponent = () => {
    return (
      <Routes>
        {
          routesDict.map((route: RouteTab) => (
            <Route
              key={route.key}
              path={route.path}
              element={<route.component />}
            />
          ))
        }
      </Routes>
    )
}

export {RoutesComponent}