import React from "react";
import ActionAlert from "@/components/Notifications/Alert";
import { Box } from "@mui/material";
import { useTypedSelector } from "@/store";
import { AlertAttr } from "@/utils/types/store/alert/alert";
import { selectAlerts } from "@/store/alerts/alertsSlice";

const AlertBox = () => {
  
  const alerts = useTypedSelector(selectAlerts)
  
  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        zIndex: 1000,
        top: "54px",
        right: "10px",
      }}
    >
      {
        alerts.map((item: AlertAttr, index: number) => (
          <React.Fragment key={index}>
            <ActionAlert
              id={index}
              severity={item.severity}
              message={item.message}
              open={item.open}
              collapseTime={item.collapseTime}
            />
          </React.Fragment>
        ))
      }
    </Box>
  )
}

export default AlertBox