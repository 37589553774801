import React from "react";
import {
  ToggleButtonGroup
} from "@/components/Buttons";
import {
  TaskDebugIcon,
  TaskErrorIcon,
  TaskInfoIcon,
  TaskWarningIcon
} from "@/components/Icons/MuiIcons";
import { useAppDispatch } from "@/store";
import {setTaskLogFilter} from "@/store/tasks/taskLogFilter";

interface TaskLogFilterProps {
  errors: number | undefined;
  warnings: number | undefined;
  debugs: number | undefined;
}

const TaskLogFilter: React.FC<TaskLogFilterProps> = (
  props: TaskLogFilterProps
) => {
  
  const warningsCount = props.warnings ? `${props.warnings.toString()}` : "0";
  const errorsCount = props.errors ? `${props.errors.toString()}` : "0";
  const debugsCount = props.debugs ? `${props.debugs.toString()}` : "0";
  
  const [
    filterValue,
    setFilterValue
  ] = React.useState<string | null>(null)
  
  const buttonsToggle = [
    {
      value: 'Info',
      ariaLabel: 'Info',
      dataTestId: 'Info',
      text: 'Info',
      iconElement: <TaskInfoIcon/>
    },
    {
      value: 'Warning',
      ariaLabel: 'Warning',
      dataTestId: 'Warning',
      text: `Warning (${warningsCount})`,
      iconElement: <TaskWarningIcon/>
    },
    {
      value: 'Error',
      ariaLabel: 'Error',
      dataTestId: 'Error',
      text: `Error (${errorsCount})`,
      iconElement: <TaskErrorIcon/>
    },
    {
      value: 'Debug',
      ariaLabel: 'Debug',
      dataTestId: 'Debug',
      text: `Debug (${debugsCount})`,
      iconElement: <TaskDebugIcon/>
    }
  ]
  
  const handleChange = (event: React.MouseEvent<HTMLElement>, newFilter: string | null) => {
    setFilterValue(newFilter)
  }
  
  const dispatch = useAppDispatch();
  
  React.useEffect(() => {
    dispatch(setTaskLogFilter(filterValue))
  }, [filterValue, dispatch]);
  
  return (
    <ToggleButtonGroup
      value={filterValue}
      exclusive
      onChange={handleChange}
      ariaLabel={'filter'}
      size={"small"}
      buttonsToggle={buttonsToggle}
    />
  )
};

export default TaskLogFilter;