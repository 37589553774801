import React, { useCallback } from "react";
import { ListItemButton } from "@mui/material";

interface ListButtonProps {
  name?: string;
  value: string;
  selected: boolean;
  handleClickEvent: (value: string) => void;
  children?: React.ReactNode;
}


const ListButton: React.FC<ListButtonProps> = (
  props: ListButtonProps
) => {
  
  const handleClickButton = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      value: string
    ) => {
      props.handleClickEvent(value);
  }, [props])
  
  return (
    <ListItemButton
      sx={{
        display: 'block',
        width: '100wv',
      }}
      selected={props.selected}
      onClick={(event) => handleClickButton(event, props.value)}
      >
      {props.name && props.name} {props.children && props.children}
    </ListItemButton>
  )
}

export default ListButton;