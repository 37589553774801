import React, { FC, ReactElement } from "react";
import {  useIsAuthenticated } from "@azure/msal-react";
import {
  Container,
  MenuItem,
  Toolbar,
  Box,
  Typography,
  FormControl,
  IconButton,
  Menu,
  Link
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import routesDict from "@/service/Routes/routesMap";
import { NavLink } from "react-router-dom";
import {SignInButton} from "@/components/NavBar/AuthMSALButton/SignInButton";
import { AuthenticatedTemplate } from "@azure/msal-react";
import RouteTab from "@/utils/types/service/route";

// Config user account
import NavbarAccMenu from "@/components/NavBar/AccountMenu/NavbarAccMenu";
import ServerMenu from "@/components/NavBar/ConfigureServer/ServerMenu";

const NavBar: FC = (): ReactElement => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget);
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  }

  const isAuthenticated = useIsAuthenticated();

  return (
    <Box sx={{
      width: '100%',
      height: '42px',
      background: "linear-gradient(#FAFAFA, #CFD4E0)"
    }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              ml: 2,
              mr: 4,
              mt: -10,
              display: { xs: 'none', md: 'flex' },
            }}
          > Unidata Web </Typography>
          <AuthenticatedTemplate>
            <Box sx={{
              flexGrow: 1,
              mt: -10,
              display: { xs: 'none', md: 'flex' }
            }}>
              {routesDict.map((page: RouteTab) => (
                <Link
                  key={page.key}
                  component={NavLink}
                  to={page.path}
                  color="black"
                  underline="none"
                  variant="button"
                  sx={{ fontSize: "large", marginLeft: "2rem" }}
                >
                  {page.title}
                </Link>
                )
              )}
            </Box>
            <Box sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' }
            }}>
              <IconButton
                size="large"
                aria-label="open drawer"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {routesDict.map((page:RouteTab) => (
                  <Link
                    key={page.key}
                    component={NavLink}
                    to={page.path}
                    color="black"
                    underline="none"
                    variant="button"
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page.title}</Typography>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>
          </AuthenticatedTemplate>

          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row-reverse',
            width: '280px',
            right: '10px',
            mt: -10,
            textAlign: 'right'
          }}>
            <FormControl>
              { isAuthenticated ?
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: '10px'
                }}>
                  <NavbarAccMenu />
                  <ServerMenu />
                </Box>
                :
                <Box>
                  <SignInButton/>
                </Box>
              }
            </FormControl>
          </Box>
        </Toolbar>
      </Container>
    </Box>
  )
}

export default NavBar;