const CountryCode : { [key: number] : string } = {
    0: "Russia",
    1: "England",
    2: "USA",
    3: "France",
    4: "Norway",
    5: "Sweden",
    6: "Finland",
    7: "Germany",
    8: "Australia",
    9: "NewZealand",
    10: "IntOrganisations",
    11: "Denmark",
    12: "Holland",
    13: "SouthKorea",
    14: "Spain",
    15: "Canada",
    16: "SouthAfricanRepublic",
    17: "India",
    18: "Brazil",
    19: "Surinam",
    20: "Poland",
    21: "Argentina",
    22: "Ecuador",
    23: "Italy",
    24: "Latvia",
    25: "Estonia",
    26: "Chile",
    27: "Iceland",
    28: "Indonesia",
    29: "Croatia"
}

const OwnerCountryCode : { [key: number] : string } = {
    0: "NotDefined",
    1: "Russia",
    2: "England",
    3: "USA",
    4: "France",
    5: "Norway",
    6: "Sweden",
    7: "Finland",
    8: "Germany",
    9: "Australia",
    10: "NewZealand",
    11: "Denmark",
    12: "Holland",
    13: "SouthKorea",
    14: "Spain",
    15: "Canada",
    16: "SouthAfricanRepublic",
    17: "India",
    18: "Brazil",
    19: "Surinam",
    20: "Poland",
    21: "Argentina",
    22: "Ecuador",
    23: "Italy",
    24: "Latvia",
    25: "Estonia",
    26: "Chile",
    27: "Iceland",
    28: "Indonesia",
    29: "Croatia"
}

const ChartType: { [key: number] : string } = {
    0: "Not verified",
    100: "Verified",
    200: "Republished",
    301: "(not used) Unofficial materials",
    302: "(spec2) Experienced (official)",
    303: "(spec3) Conversion result (unofficial from official sources)",
    304: "(spec4) Conversion result (unofficial)",
    305: "(spec5) Conversion result (official)",
    306: "(not used) For special use",
    307: "(spec7) For internal use (zone coding)",
    501: "(Aux1) Replaced by an equivalent from the S57 set",
    502: "(Aux2) Fishery",
    503: "(Aux3) Bathymetric",
    504: "(Aux4) Private (by special request)",
    505: "(Aux5) Auxiliary",
    506: "(Aux6) Removed from the collection",
    507: "(Aux7) Test"
}

export { CountryCode, OwnerCountryCode, ChartType };
