import React from 'react';
import { useTypedSelector } from "@/store";
import {selectServerName, selectServerUrl} from "@/store/authBE/backendServerUrl";
import {
  Box, IconButton,
  Tooltip, Menu, MenuItem, Typography
} from "@mui/material";
import DnsIcon from '@mui/icons-material/Dns';
import ConfigBackEnd from "@/components/NavBar/ConfigureServer/ConfigBackEnd";
import { useGetServerHealthQuery } from "@/store/general/generalApi";

// TODO: please add component for menu with tooltip

const ServerMenu = () => {
  
  const serverName = useTypedSelector(selectServerName);
  const serverUrl = useTypedSelector(selectServerUrl);
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const {
    isError: isServerNotAvialable
  } = useGetServerHealthQuery({serverUrl: serverUrl}, {
    pollingInterval: 30000,
    refetchOnFocus: true,
  });
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }
  
  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Tooltip title="Server detail">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, gap: 1 }}
            aria-controls={open ? 'server-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            >
            { !isServerNotAvialable && <>
              <DnsIcon sx={{ color: "green" }} />
              <Typography>
                server: {serverName}
              </Typography>
            </>  }
            { isServerNotAvialable && <>
              <DnsIcon sx={{ color: "red" }} />
              <Typography color="red">
                server: {serverName}
              </Typography>
            </> }
            
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{p: 0, "&:hover": {backgroundColor: "transparent", }}} >
          <ConfigBackEnd />
        </MenuItem>
      </Menu>
    </>
  )
}

export default ServerMenu;