import TextField from '@mui/material/TextField';

interface ValidationProps {
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void,
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
    condition: (value:any) => boolean,
    value: string | number | boolean
}

const ValidationEditor: React.FC<ValidationProps> =({
    onChange, onKeyDown, onBlur, condition, value
}) => {
        return <TextField
                    variant="standard"
                    autoFocus={true}
                    value={value}
                    error={!condition(value)}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                />
}

export default ValidationEditor