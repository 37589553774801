import React from "react";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '@/service/Auth/Main/config/msalConfig';
import { Button } from '@/components/Buttons';

const buttonStyle = {
  background: 'linear-gradient(#FAFAFA, #CFD4E0)'
}

export const SignInButton = () => {
  const {instance} = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
    });
  };

  return <Button
    style={buttonStyle}
    typeButton="button"
    disabled={false}
    testId="test-logIn-button"
    variant="contained"
    size={"small"}
    onClick={()=> handleLogin()}
  >
    Sign in
  </Button>
};