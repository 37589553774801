import React from 'react';
import { useTypedSelector } from '@/store';
import { selectTag, selectStatus } from '@/store/tasks/requestTasks';
import { useGetTasksByTimeQuery } from '@/store/tasks/tasksAPI';
import { selectServerUrl } from '@/store/authBE/backendServerUrl';
import {Grid, Paper, Typography, Box} from "@mui/material";
import { Accordion } from "@/components/Accordion";
import TaskInfo from "@/pages/TaskManager/PipelineDetails/TaskInfo";
import TaskList from "@/pages/TaskManager/PipelineDetails/TaskList";

const PipelineDetails = () => {
  
  const serverUrl = useTypedSelector(selectServerUrl);
  const tag = useTypedSelector(selectTag);
  const status = useTypedSelector(selectStatus);
  
  const {
    data: pipelineTasks,
    isLoading: isLoadingPipelineTasks,
    isError: isErrorPipelineTasks,
    isFetching: isFetchingPipelineTasks
  } = useGetTasksByTimeQuery({serverUrl: serverUrl, age: "", tag: tag, updated: ""},
    {
      skip: tag === '',
      refetchOnMountOrArgChange: true,
      pollingInterval:  status === 'Pending' || status === 'In progress' ? 10000 : 0
    }
  );
  
  return (
    <>
      { (isFetchingPipelineTasks || isLoadingPipelineTasks) && <Typography> Loading data... </Typography> }
      { isErrorPipelineTasks && <Typography>Something went wrong</Typography> }
      { !isFetchingPipelineTasks && pipelineTasks && pipelineTasks.length > 0 &&
        <Grid
          container
          spacing={3}
          sx={{ mt: 3 }}
        >
          <Grid item xs={12}>
            <Paper elevation={1}>
              <Box sx={{
                display: {
                  xs: 'none', md: 'block'
                }, px: "5px", py: "5px"
              }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Pipeline Info
                </Typography>
                <TaskInfo taskData={pipelineTasks} />
              </Box>
              <Box sx={{
                display: {
                  xs: 'block', md: 'none'
                },
                
              }}>
                <Accordion id={'pipelineinfo'} title={'Pipeline Info'}>
                  <TaskInfo taskData={pipelineTasks} />
                </Accordion>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <Box
                sx={{
                  display: {xs: 'none', md: 'block'}, maxHeight: 'calc(100vh - 359px)', overflowY:"auto", minHeight: '25px'
                }}
              >
                <Typography variant="h6" component="h2" gutterBottom sx={{mx: "20px", my: "4px"}}> Tasks </Typography>
                <TaskList taskData={pipelineTasks} />
              </Box>
              <Box sx={{display: {
                  xs: 'block', md: 'none'
                }, maxHeight: 'calc(100vh - 359px)', overflowY:"auto", minHeight: '25px' }}>
                <Accordion
                  id={'pipelinelist'}
                  title={'Tasks List'}
                >
                  <TaskList taskData={pipelineTasks} />
                </Accordion>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      }
    </>
  )
};


export default PipelineDetails;