import {Task} from "@/utils/types/store/tasks/backendAPI";

function checkPipelineStatus(taskData: Task[]) {
  const statuses = Object.values(taskData).map(({ status }) => status);
  if (statuses.includes('failed')) {
    return 'Failed';
  } else if (statuses.includes('running')) {
    return 'In progress';
  } else if (statuses.includes('pending')) {
    return 'Pending';
  } else if (statuses.includes('canceled')) {
    return 'Canceled';
  } else if (statuses.every((status: string) => status === 'done')) {
    return 'Finished';
  } else {
    return 'Unknown';
  }
}

export default checkPipelineStatus;