import React from 'react';
import { Task } from '@/utils/types/store/tasks/backendAPI';
import {useAppDispatch} from '@/store';
import sortedByTime from "@/service/Tasks/SortedByTime";
import {setSimpleGuid, setSimpleStatus} from '@/store/tasks/infoTask';

import {useTypedSelector} from "@/store";
import { selectSimpleGuid } from "@/store/tasks/infoTask";

import { setStatus } from "@/store/tasks/requestTasks";
import {List, Box, Badge, CircularProgress} from '@mui/material';
import { ListButton } from "@/components/Buttons";
import {StatusBadges} from "@/components/Badges";
import {TaskErrorIcon, TaskWarningIcon} from "@/components/Icons";
import getTaskProgress from "@/service/Tasks/GetTaskProgress";
import checkPipelineStatus from "@/service/Tasks/CheckPipelineStatus";

interface TaskListProps {
  taskData: Task[]
}

const TaskList: React.FC<TaskListProps> = (props: TaskListProps) => {
  
  const dispatch = useAppDispatch();
  
  const selectedGuid = useTypedSelector(selectSimpleGuid);
  
  const reorderedTask = React.useMemo(() => {
    return sortedByTime(Object.values(props.taskData), 'asc');
  },[props.taskData])
  
  const [
    selectedIndex,
    setSelectedIndex
  ] = React.useState<string>(selectedGuid !== '' ? selectedGuid : reorderedTask[0].guid );
  
  const handleClickTask = React.useCallback((value: string) => {
    setSelectedIndex(value);
    dispatch(setSimpleGuid(value));
  }, [setSelectedIndex, dispatch]);
  
  React.useEffect(() => {
    const statusTask = checkPipelineStatus(reorderedTask);
    if (statusTask === 'Failed' || statusTask === 'Canceled' || statusTask === 'Finished') {
      dispatch(setStatus(statusTask));
    }
    dispatch(setSimpleStatus(reorderedTask.find((task) => task.guid === selectedIndex)?.status));
  }, [dispatch, reorderedTask, selectedIndex]);
  
  return (
    <List>
      {
        reorderedTask.map((contentTask) => {
          return (
            <ListButton
              key={contentTask.guid}
              value={contentTask.guid}
              selected={selectedIndex === contentTask.guid}
              handleClickEvent={handleClickTask}
              >
              <Box gap={2} sx={{
                display: 'flex',
                direction: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  pl: 2
                }}>
                  {
                    <StatusBadges status={contentTask.status}/>
                  } {
                    contentTask.name
                  } {
                  (contentTask.errors_number && contentTask.errors_number > 0) &&
                  <Badge badgeContent={contentTask.errors_number} color="secondary" max={9999}>
                    <TaskErrorIcon/>
                  </Badge>
                  } {
                    (contentTask.warnings_number && contentTask.warnings_number > 0) &&
                    <Badge badgeContent={contentTask.warnings_number} color="secondary" max={9999}>
                      <TaskWarningIcon/>
                    </Badge>
                  }
                </Box>
                <Box sx={{
                  display: 'flex',
                  minWidth: '70px',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                  {
                    contentTask.status === 'running'
                      ?
                      <>
                        <CircularProgress size={15} sx={{ml: 4, mr: 2}}/>
                        {getTaskProgress({
                          progress_current: contentTask.progress_current,
                          progress_total: contentTask.progress_total,
                          estimation: contentTask.estimation,
                          time_started: contentTask.time_started,
                        })}%
                      </>
                      : <> {contentTask.status} </>
                  }
                </Box>
              </Box>
            </ListButton>
          )
        })
      }
    </List>
  )
}

export default TaskList;