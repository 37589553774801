import React, {useCallback, useEffect, useState} from "react";
import {
  DataGridPro,
  GridColDef, GridInitialState,
  GridRowProps, GridFilterModel, GridSortModel,
  useGridApiRef, GridColumnVisibilityModel, GridPinnedColumns, GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowSelectionModel, GridValidRowModel
} from '@mui/x-data-grid-pro';


interface DataGridProps {
  rows?: Array<any>;
  rowID?: (row: GridRowProps) => number;
  idProperty: string;
  gridStyle?: { [key: string] : string | number };
  columns: GridColDef[];
  loading?: boolean;

  // Edit function
  onEditComplete?: (value: string, oldValue: string, columnId: string, newData: {[key: string]: string | number | boolean}) => void;
  onEditStart?: () => void;
  onEditCancel?: () => void;
  
  // initialDataGridState
  initialState: GridInitialState;
  updatedState?: (gridState: GridInitialState) => void;
  
  // selection
  initialSelection: GridRowSelectionModel;
  updateSelection?: (selectionModel: GridValidRowModel) => void;
}

function getRowId(row: GridRowProps) {
  return row.EncCellID;
}

const DataGrid = (props: DataGridProps) => {
  
  // useGridApiRef for additional actions
  const apiRef = useGridApiRef();
  
  // controlled header
  
  const [
    filterModel,
    setFilterModel
  ] = useState<GridFilterModel>((props.initialState.filter && props.initialState.filter.filterModel) || {items: []});
  
  const handleFilterModelChange = useCallback((newModel: GridFilterModel) => setFilterModel(newModel), []);
  
  const [
    sortModel,
    setSortModel
  ] = React.useState<GridSortModel>((props.initialState.sorting && props.initialState.sorting.sortModel) || []);
  
  const handleSortModelChange = useCallback((newSortModel: GridSortModel) => setSortModel(newSortModel), []);
  
  
  const [
    columnVisibilityModel,
    setColumnVisibilityModel
  ] = useState<GridColumnVisibilityModel>((props.initialState.columns && props.initialState.columns.columnVisibilityModel) || {});
  
  const handleColumnVisibilityModelChange = useCallback(
    (updatedModel: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(updatedModel);
    }, []
  );
  
  const [
    pinnedColumns,
    setPinnedColumns
  ] = useState<GridPinnedColumns>({...props.initialState.pinnedColumns,
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field]
  } || {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field]
  });
  
  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns);
    }, [],
  );
  
  // selection configurations
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>(props.initialSelection);
  
  const handleRowSelectionModelChange = useCallback((newSelection: GridRowSelectionModel) => {
    setRowSelectionModel(newSelection);
  }, []);
  
  
  // Edit function
  const onEditStart = useCallback(() => {
    props.onEditStart && props.onEditStart();
  },[props]);
  
  const onEditCancel = useCallback(() => {
    props.onEditCancel && props.onEditCancel();
  }, [props]);
  
  const onEditComplete = useCallback(
    async(newRow: any, oldRow: any) => {
      let columnId = '';
      for (let key in newRow) {
        if (newRow[key] !== oldRow[key]) {
          columnId = key;
          break;
        }
      }
      if (columnId !== '') {
        props.onEditComplete && props.onEditComplete(newRow[columnId], oldRow[columnId], columnId, newRow);
      }
      return newRow;
    }, [props],
  );
  
  useEffect(() => {
    props.updatedState && props.updatedState(apiRef.current.exportState())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModel, sortModel, columnVisibilityModel, pinnedColumns, apiRef])
  
  
  useEffect(() => {
    if (apiRef.current.getSelectedRows().size > 0) {
      console.log(apiRef.current.getSelectedRows())
      const selectionMap = Array.from(apiRef.current.getSelectedRows()).map((item)=>{
        return item[1]
      });
      props.updateSelection && props.updateSelection(selectionMap);
    } else {
      props.updateSelection && props.updateSelection([]);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, rowSelectionModel])
  
  return (
    <DataGridPro
      sx={{
        '& .super-app.green-highlight':{
          backgroundColor: '#f2f9de'
        },
        '& .super-app.red-highlight':{
          backgroundColor: '#ffb4b4'
        }
      }}
      style={props.gridStyle}
      columns={props.columns}
      apiRef={apiRef}
      loading={props.loading || undefined}
      rows={props.rows || []}
      getRowId={props.rowID || getRowId}

      // Edit function
      onCellEditStart={onEditStart} //onEditStart={onEditStart}
      onCellEditStop={onEditCancel} //onEditStop={onEditCancel}
      processRowUpdate={onEditComplete} //onEditComplete={onEditComplete}

      // selection configurations
      checkboxSelection
      disableRowSelectionOnClick
      onRowSelectionModelChange={handleRowSelectionModelChange}
      rowSelectionModel={rowSelectionModel}
      
      // filter only for check state
      filterModel={filterModel}
      onFilterModelChange={handleFilterModelChange}
      
      // sorting only for check state
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      
      // columns state
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      pinnedColumns={pinnedColumns}
      onPinnedColumnsChange={handlePinnedColumnsChange}
    />
  )
}

export default DataGrid