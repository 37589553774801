import Map, {Source, Layer} from 'react-map-gl';
import { mbToken } from "@/service/MapBox/mapboxToken";
import 'mapbox-gl/dist/mapbox-gl.css';
import * as React from "react";

function MainMap() {
  const [viewState, setViewState] = React.useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 8,
  });

  const wmsSource = {
    type: "raster" as "raster",
    tiles: ["https://gotms-unidata-stage.transas.com:8003/api/tiles/3_UTT_AIR/0-6/{z}/{x}/{y}.png"],
    tileSize: 256
  }

  const wmsLayer = {
    id: "wms-layer",
    type: "raster" as "raster",
  };

  return (
    <Map
      {...viewState}
      onMove={evt => setViewState(evt.viewState)}
      style={{ position: 'absolute', bottom: 0, top:0 }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxAccessToken={mbToken}
      attributionControl={false}
    >
      <Source {...wmsSource}>
        <Layer {...wmsLayer} />
      </Source>
    </Map>
  )
}

export default MainMap;