import React from 'react';
import {Button, ButtonGroup, IconButton} from "@/components/Buttons/index";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Box, MenuItem, MenuList} from "@mui/material";
import {Popper} from "@/components/PoperWindow";


interface PropsButtonList {
  arrayList: Array<{itemIndex: number, message: string}>;
  actionLabel: string;
  keyEventArray?: string;
  icon?: React.ReactElement;
  onClickMain: () => void;
  selectedCondition: "selectedOneItem" | "selectedLTItem" | "selectedGTItem";
  disabled?: boolean;
  onClickListItem: (index: number) => void;
  placement: "bottom" | "left" | "right" | "top" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | undefined;
}

const ButtonList: React.FC<PropsButtonList> = (props: PropsButtonList) => {
  
  const listRef = React.useRef<HTMLDivElement>(null);
  
  const [openWindow, setOpenWindow] = React.useState(false);
  
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);
  
  const handleToggle = React.useCallback(() => {
    setOpenWindow((prevOpen) => !prevOpen);
  }, []);
  
  const handleClose = React.useCallback(() => {
    setOpenWindow(false);
  }, []);
  
  const handleMenuItemClick = React.useCallback((
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpenWindow(false);
    props.onClickListItem(index);
  },[props])
  
  const selectFunc = (
    condition: "selectedOneItem" | "selectedLTItem" | "selectedGTItem",
    indexArray: number,
    indexSelected: number) => {
    switch (condition) {
      case "selectedLTItem":
        return indexArray <= indexSelected;
      case "selectedGTItem":
        return indexArray >= indexSelected;
      default:
        return indexArray === indexSelected;
    }
  }
  
  return (
    <>
      <Box ref={listRef}>
        <ButtonGroup
          variant="contained"
          color={"info"}
          size="small"
          ariaLabel={`${props.actionLabel}-buttons`}
          testId={`test-${props.actionLabel}-buttons`}
        >
          <IconButton
            size={"small"}
            typeButton="button"
            disabled={props.disabled || false}
            testId="test-undo-button"
            onClick={() => {
              props.onClickMain();
            }}
            keyEvent={props.keyEventArray}
          >
            {props.icon || <QuestionMarkIcon />}
          </IconButton>
          <Button
            color={"primary"}
            variant={"text"}
            typeButton="button"
            disabled={props.disabled || false}
            testId={`test-open-${props.actionLabel}-list`}
            onClick={handleToggle}
            ariaLabel={`open-${props.actionLabel}-list`}
            ariaHaspopup="menu"
            ariaControls={openWindow ? `${props.actionLabel}-list` : undefined}
            ariaExpanded={openWindow}
          >
            <ExpandMoreIcon />
          </Button>
        </ButtonGroup>
      </Box>
      <Popper
        open={openWindow}
        anchorEl={listRef.current}
        placement={props.placement}
        closeAction={handleClose}
      >
        <MenuList
          id={`${props.actionLabel}-list`}
          autoFocusItem
        >
          {
            props.arrayList.map((item, index) => (
              <MenuItem
                key={`${props.actionLabel}-list-${index}`}
                selected={(selectedIndex || selectedIndex === 0) ? selectFunc(props.selectedCondition, item.itemIndex, selectedIndex) : false}
                onClick={(event) => handleMenuItemClick(event, item.itemIndex)}
                onMouseEnter={() => {
                  setSelectedIndex(item.itemIndex);
                }}
              >
                {item.message}
              </MenuItem>
            ))
          }
        </MenuList>
      </Popper>
    </>
  )
}

export default ButtonList;